import { createRoute } from '@tanstack/react-router';

import {authWithNavRoute } from '../../router/authRoute.tsx';
import { Profile } from './profile.tsx';

export const profileRoute = createRoute({
	path: '/profile',
	component: Profile,
	getParentRoute: () => authWithNavRoute,
});
