import * as yup from "yup";
import { insuranceTypeCAN } from "../type.ts";

export const healthCardValidationSchema = yup.object().shape({
  insuranceType: yup.string().oneOf(Object.keys(insuranceTypeCAN)).optional(),
  insuranceNumber: yup.string().required('insurance number is required'),
  versionCode: yup.string().required('version is required'),
  expiresOn: yup.date().required('expires date is required'),
  issuedOn: yup.date().required('issued date is required'),
})
