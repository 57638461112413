import { create } from 'zustand';

type NavbarToggleStatus = 'collapsed' | 'minimized' | 'maximized';

type ToggleStatusSet =
	// collapsed
	| ['collapsed', 'collapsed'] // force only collapsed
	| ['collapsed', 'minimized']
	| ['collapsed', 'maximized']
	// maximized
	| ['maximized', 'maximized'] // force only maximized
	| ['maximized', 'minimized']
	| ['maximized', 'collapsed']
	// minimized
	| ['minimized', 'minimized'] // force only minimized
	| ['minimized', 'maximized']
	| ['minimized', 'collapsed']

type NavbarState = {
	isMaximized: boolean;
	isMinimized: boolean;
	isDrawerCollapse: boolean;
	toggleSet: ToggleStatusSet
	status: NavbarToggleStatus;
};

type NavbarActions = {
	setDrawerMinimized: () => void;
	setDrawerMaximized: () => void;
	setDrawerCollapsed: () => void;
	toggleDrawerStatus: () => void;
	unsetDrawerStatus: (status: NavbarToggleStatus) => void;
	setDrawerStatus: (status: NavbarToggleStatus) => void;
	setToggleStatusSet: (toggleSet: ToggleStatusSet) => void
};

export type UseNavbarStore = NavbarState & NavbarActions;

export const useNavbarStore = create<UseNavbarStore>((set, get) => ({
	status: 'maximized',
	isDrawerCollapse: false,
	isMinimized: false,
	isMaximized: true,
	toggleSet: ['maximized', 'minimized'],
	setToggleStatusSet(toggleSet: ToggleStatusSet) {
		set({ toggleSet })
	},
	unsetDrawerStatus(clearStatus: NavbarToggleStatus) {
		set((s) => {
			const [statusA, statusB] = [...s.toggleSet];
			const nextStatus = statusA !== clearStatus ? statusA: statusB;
			const status = s.status === clearStatus ? nextStatus : s.status;

			return {
				status,
				isMaximized: status === 'maximized',
				isMinimized: status === 'minimized',
				isDrawerCollapse: status === 'collapsed'
			}
		})
	},
	setDrawerStatus(status: NavbarToggleStatus) {
		return set({
			status,
			isMaximized: status === 'maximized',
			isMinimized: status === 'minimized',
			isDrawerCollapse: status === 'collapsed',
		})
	},
	setDrawerMinimized() {
		get().setDrawerStatus('minimized');
	},
	setDrawerMaximized() {
		get().setDrawerStatus('maximized');
	},
	setDrawerCollapsed() {
		get().setDrawerStatus('minimized');
	},
	toggleDrawerStatus() {
		set((s) => {
			const [statusA, statusB] = [...s.toggleSet];
			const status = s.status === statusA ? statusB: statusA;

			return {
				status,
				isMaximized: status === 'maximized',
				isMinimized: status === 'minimized',
				isDrawerCollapse: status === 'collapsed'
			}
		})
	},
}));

// @ts-ignore
window.useNavbarStore = useNavbarStore;
