// @ts-nocheck
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { ValidationType } from '../../../../../lib/type.ts';

import { getFirstErrorMessage } from './_helpers';

import BodySection from '../Section';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const AppointmentTypeLabel = {
	uti: 'Urinary tract infection (UTI)',
	prescription: 'Prescription refill',
	mentalHealth: 'Mental Health',
	doctorsNote: `Doctor's note`,
	respiratoryComplaint: 'Respiratory complaint (cold, flu, fever, COVID-19)',
	digestiveIssue: 'Digestive issue (constipation, acid reflux, etc.)',
	dermatology: 'Skin rash or issue',
	other: 'Other',
} as const;

const section = {
	sectionTitle: 'What is the reason for your visit today?',
	firstFieldId: 'appointmentType',
	requiredSection: true,
	defaultValue: 'Private Pay',
	appointmentType: Object.entries((AppointmentTypeLabel as unknown) as Record<string, string>),
};

const useStyles = makeStyles(() => ({
	labelStyle: {
		display: 'flex',
		width: '45%',
		['@media (max-width:550px)']: {
			width: '80%',
		},
	},
}));

const AppointmentType = () => {
	const classes = useStyles();
	const { errors, control } = useFormContext();

	const { sectionTitle, firstFieldId, requiredSection, appointmentType, defaultValue } = section;

	const firstErrorMessage: string = getFirstErrorMessage({
		validationType: ValidationType.Required,
		sectionErrors: errors,
		id: firstFieldId,
	});

	return (
		<BodySection
			title={sectionTitle}
			firstLabelName={firstFieldId}
			required={requiredSection}
			errorMessage={firstErrorMessage}
		>
			<Controller
				as={
					<RadioGroup row aria-label="appointmentType" defaultValue={defaultValue}>
						{appointmentType.map(([value, label]) => (
							<FormControlLabel
								key={label.replace(/\s+/g, '')}
								className={classes.labelStyle}
								value={value}
								control={<Radio color={'primary'} />}
								label={label}
							/>
						))}
					</RadioGroup>
				}
				name={firstFieldId}
				control={control}
				required={requiredSection}
				defaultValue={defaultValue}
			/>
		</BodySection>
	);
};

export default AppointmentType;
