import { useMemo } from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export function useBreakpoints() {
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const isSM = useMemo(() => smDown, [smDown])
  const isMD = useMemo(() => !smDown && mdDown, [smDown, mdDown])
  const isLG = useMemo(() => !mdDown && mdUp, [mdDown, mdUp])

  return {
    isSM,
    isMD,
    isLG,
  }

}
