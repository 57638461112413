import { ReactNode, useCallback } from 'react';
import {
	GrowthBook,
	useGrowthBook,
	GrowthBookProvider,
	FeaturesReady as FeaturesReadyGB,
	useFeatureIsOn as useFeatureIsOnGB,
	useFeatureValue as useFeatureValueGB,
} from '@growthbook/growthbook-react';

export type JSONValue = null | number | string | boolean | Array<JSONValue> | { [key: string]: JSONValue };

const ENABLE_DEV_MODE = ['dev','localhost'].includes(import.meta.env.VITE_SYSTEM_ENV);

// Create a GrowthBook instance
const growthBookInstance = new GrowthBook({
	enableDevMode: ENABLE_DEV_MODE,
	apiHost: import.meta.env.VITE_GROWTHBOOK_HOST,
	clientKey: import.meta.env.VITE_GROWTHBOOK_KEY,
	// features: {},
	features: {
		configure_patient_document_hide_add: {
			defaultValue: true,
		},
		configure_patient_appointment_hide_followup: {
			defaultValue: false,
		},
		configure_patient_appointment_hide_join: {
			defaultValue: true,
		},
	},
});

/**
 * @typedef {JSON|string|number|boolean} Primitives
 *
 * @interface WidenPrimitives
 * @type {Primitives}
 */

// Load Feature
growthBookInstance
	.loadFeatures({
		skipCache: true,
		timeout: 1000 * 60 * 3, // 3 min
	})
	.then(() => {
		if (ENABLE_DEV_MODE) {
			console.info('🏁 feature', 'ready');
		}
	})
	.catch((error) => {
		if (ENABLE_DEV_MODE) {
			console.error('🏁 feature', error);
		}
	});

/**
 * Get Feature value React Hook
 *
 * @param {string} id - feature toggle key
 * @param {*} fallback - fallback value
 */
export function useFeatureValue<T extends JSONValue = any>(id: string, fallback: T) {
	return useFeatureValueGB<T>(id, fallback);
}

/**
 * Get if feature is Enabled (on)
 * @param {string} id - feature toggle key
 */
export function useFeatureIsOn(id: string) {
	return useFeatureIsOnGB(id);
}

/**
 * Get User Attribute API React Hook
 */
export function useFeatureUser() {
	const growthbook = useGrowthBook();

	const getAttributes = useCallback(() => {
		return growthbook?.getAttributes();
	}, [growthbook]);

	const setAttributes = useCallback(
		(attributes: any) => {
			return growthbook?.setAttributes(attributes);
		},
		[growthbook],
	);

	return {
		getAttributes,
		setAttributes,
	};
}


export type IfFeatureProps = {
	feature: string;
	children?: ReactNode;
} & (
	| {
			isEnabled: boolean;
	  }
	| {
			notEnabled: boolean;
	  }
);

/**
 * Conditional for Feature Toggle, React Component
 */
export function IfFeature(props: IfFeatureProps) {
	const { feature, children, isEnabled, notEnabled } = props as IfFeatureProps & {
		isEnabled: boolean;
		notEnabled: boolean;
	};
	const isFeatureOn = useFeatureIsOn(feature);

	const isShowIfFeatureEnable = isFeatureOn && isEnabled;
	const isShowIfFeatureNotEnable = !isFeatureOn && notEnabled;

	if (isShowIfFeatureEnable || isShowIfFeatureNotEnable) {
		return children;
	}

	return null;
}

export type FeaturesReadyProps = {
	timeout: number;
	fallback: ReactNode;
	children: ReactNode;
};

export function FeaturesReady(props: FeaturesReadyProps) {
	const { timeout, fallback, children } = props;

	return (
		<FeaturesReadyGB timeout={timeout} fallback={fallback}>
			{children}
		</FeaturesReadyGB>
	);
}

export type FeatureToggleProviderProps = {
	children: ReactNode;
}

export function FeatureToggleProvider(props: FeatureToggleProviderProps) {
	return <GrowthBookProvider growthbook={growthBookInstance}>{props?.children}</GrowthBookProvider>;
}

export const featureToggle = {
	/**
	 * @alias {gb.getAttributes}
	 */
	getAttributes() {
		return growthBookInstance.getAttributes();
	},

	/**
	 * @alias {gb.setAttributes}
	 */
	async setAttributes(attributes: Record<string, any>) {
		await growthBookInstance.setAttributes(attributes);
		await growthBookInstance.refreshFeatures({
			skipCache: true,
		});
	},

	/**
	 * @alias {gb.isOn}
	 * @param {string} key - feature toggle name
	 */
	isOn(key: string) {
		return growthBookInstance.isOn(key);
	},

	/**
	 * @alias {gb.isOff}
	 * @param {string} key - feature toggle name
	 */
	isOff(key: string) {
		return !growthBookInstance.isOn(key);
	},

	/**
	 *
	 * @alias {gb.getFeatureValue}
	 * @param {string} key - feature toggle name
	 * @param {*} fallback
	 */
	getFeatureValue<T extends any>(key: string, fallback: T) {
		return growthBookInstance.getFeatureValue(key, fallback) as T;
	},
};

export const FeatureToggle = {
	Provider: FeatureToggleProvider,
	Ready: FeaturesReady,
	If: IfFeature,
};

if (import.meta.env.NODE_ENV === 'development') {
	//@ts-ignore
	window.__gb__ = {
		growthBookInstance,
		featureToggle,
		FeatureToggle,
	};
}
