import { memo } from 'react';

import { Grid } from '@mui/material';

import ChildrenProps from '../../lib/type.ts';

interface ContainerProps extends ChildrenProps {
	spacing?: number;
	centered?: boolean;
}

function Section(props: ContainerProps) {
	const { children, spacing, centered, className } = props;

	return (
		// @ts-ignore
		<Grid
			container
			spacing={spacing}
			className={className}
			alignItems={centered && 'center'}
			justifyContent={centered && 'center'}
			direction={centered ? 'column' : undefined}
		>
			{children}
		</Grid>
	);
}

export default memo(Section);
