import { useQuery } from 'react-query';
import { get } from '../../../lib/api/_helper';
import { Pharmacy } from '../../../lib/type.ts';

export const useGetPharmacyList = (postalCode: string = '', Cookie = {}) => {
	const fixedPostalCode = postalCode?.replace(/_/g, '').replace(/ /g, '');
	const url = `/api/pharmacy_locations?limit=50&&postal_code=${fixedPostalCode}`;
	const config = {
		baseURL: `${import.meta.env.VITE_API}`,
		headers: { Cookie, cache: 'no-cache' },
	};

	const { data } = useQuery<Pharmacy[]>(['getPharmacies', fixedPostalCode], () => get(url, config), {
		refetchInterval: false,
		refetchIntervalInBackground: false,
		refetchOnReconnect: false,
		enabled: fixedPostalCode?.length > 5,
	});

	const pharmacies = data?.sort((a, b) => a['distance_in_mi'] - b['distance_in_mi']);

	console.log('pharmacies', { count: pharmacies?.length });

	return pharmacies;
};
