import axios from 'axios';
import { FormType } from '../type.ts';
interface Props {
  base64URL: string;
  fileName: string;
  url: string;
  formType: FormType;
}

export const uploadFile = async ({ base64URL, fileName, url, formType }: Props) => {
  const apiURL = `${import.meta.env.VITE_APIV2}/apiv2/pt/${url}`;

  const params = {
    fileDataBase64: base64URL?.split(';base64,')[1],
    fileName,
    fileType: base64URL.split(';base64,')[0],
    formType
  };

  const { data, status } = await axios.post(apiURL, params, { withCredentials: true});

  if (status !== 200) {
    throw new Error('upload file failed');
  }

  return data;
};
