import Snackbar from '@mui/material/Snackbar';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Alert } from '@mui/lab';

interface Props {
  text: string;
  severity: 'success' | 'error' | 'warning' | 'info';
  show: boolean;
  duration?: number;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme?.spacing?.(2),
    },
  },
}));

export default function CustomToast({ text, severity, onClose, show = false, duration = 6000 }: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(show), [show]);

  const handleClose = (_event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onClose?.();
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}
