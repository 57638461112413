import { useQuery } from 'react-query';
// import { dehydrate } from 'react-query/hydration';
import { get } from '../api/_helper';
import { Document } from '../type.ts';

const url = `apiv2/pt/patients/medicalForms`

export const useGetDocuments = () => {
  return useQuery<Document[]>('documents', () => get(url));
};

