import LockIcon from '@mui/icons-material/Lock';
import LoadingButton from '@mui/lab/LoadingButton';
import CircleIcon from '@mui/icons-material/Circle';
import { enqueueSnackbar } from 'notistack';
import ListItemText from '@mui/material/ListItemText';
import { createRoute, useNavigate } from '@tanstack/react-router';
import { useState, useEffect, useCallback, FormEvent } from 'react';
import { Box, Grid, Typography, TextField, FormControl, List, ListItem, ListItemIcon, Stack } from '@mui/material';

import RDLogoLight from '../../components/Common/RdLogoLight.tsx';
import { validatePassword } from '../../lib/_helpers.ts';
import { rootRoute } from '../../router/rootRoute.tsx';
import { post } from '../../lib/api/_helper.ts';

export const passwordResetRoute = createRoute({
	component: PasswordReset,
	path: '/password-reset',
	getParentRoute: () => rootRoute,
});

const PasswordHint = ({ variant }: { variant?: 'text' | 'list' }) => {
	if (variant === 'text') {
		return (
			<Typography gutterBottom variant="body2">
				Password must be 10 characters length, one upper case character, one special character (!@#$&*), one numeral
				(0-9) and one letters in lower case.
			</Typography>
		);
	}

	return (
		<Stack alignItems="flex-start" sx={{ width: 300 }}>
			<Typography variant="subtitle1">
				In order to protect your account, make sure your password is at least:
			</Typography>

			<List dense>
				<ListItem sx={{ padding: 0, paddingLeft: '10px' }}>
					<ListItemIcon sx={{ minWidth: '20px' }}>
						<CircleIcon sx={{ fontSize: 6 }} />
					</ListItemIcon>
					<ListItemText primary="10 characters" />
				</ListItem>
				<ListItem sx={{ padding: 0, paddingLeft: '10px' }}>
					<ListItemIcon sx={{ minWidth: '20px' }}>
						<CircleIcon sx={{ fontSize: 6 }} />
					</ListItemIcon>
					<ListItemText primary="1 special character (!@#$&*)" />
				</ListItem>
				<ListItem sx={{ padding: 0, paddingLeft: '10px' }}>
					<ListItemIcon sx={{ minWidth: '20px' }}>
						<CircleIcon sx={{ fontSize: 6 }} />
					</ListItemIcon>
					<ListItemText primary="1 Lowercase" />
				</ListItem>
				<ListItem sx={{ padding: 0, paddingLeft: '10px' }}>
					<ListItemIcon sx={{ minWidth: '20px' }}>
						<CircleIcon sx={{ fontSize: 6 }} />
					</ListItemIcon>
					<ListItemText primary="1 Uppercase" />
				</ListItem>
				<ListItem sx={{ padding: 0, paddingLeft: '10px' }}>
					<ListItemIcon sx={{ minWidth: '20px' }}>
						<CircleIcon sx={{ fontSize: 6 }} />
					</ListItemIcon>
					<ListItemText primary="1 number" />
				</ListItem>
			</List>
		</Stack>
	);
};

export function PasswordReset() {
	const navigate = useNavigate();

	const [passwordWeak, setPasswordWeak] = useState(false);
	const [tryingReset, setTryingReset] = useState(false);

	useEffect(() => {
		document.title = 'Patient reset password - Rocket Doctor';
	}, []);

	const passwordResetRequest = useCallback((evt: FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		evt.stopPropagation();

		setTryingReset(true);

		console.log('passReset', 'reset status');

		const urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get('email');
		const token = urlParams.get('token');

		console.log('passReset', 'get url params', { email, token });

		const fromData = new FormData(evt.currentTarget);
		const newPassword = fromData.get('newpassword');
		console.log('passReset', 'get form params', { newPassword, fromData });

		post(`apiv2/pt/password-reset`, {
			password: newPassword,
			email,
			token,
		})
			.then(() => {
				enqueueSnackbar('Password has been set, redirecting to login', { variant: 'success' });
				return navigate({
					replace: true,
					to: '/login',
					params: {},
				});
			})
			.catch(() => {
				setTryingReset(false);
				enqueueSnackbar('failed to reset password', { variant: 'error' });
			});
	}, []);

	return (
		<form onSubmit={passwordResetRequest}>
			<Box display="grid" p={4} gridTemplateColumns="1fr 2fr 1fr">
				<div />
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<Box display="flex" alignItems="center" justifyContent="center">
						<RDLogoLight />
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
						<Typography gutterBottom variant="h4">
							Password Reset
						</Typography>
						<Typography gutterBottom variant="h6">
							Enter a new password
						</Typography>
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
						<FormControl>
							<TextField
								required
								fullWidth
								margin="normal"
								id="password"
								key="password"
								type="password"
								name="newpassword"
								autoComplete="new-password"
								// value={password}
								variant="outlined"
								label="Enter Password"
								error={passwordWeak}
								disabled={tryingReset}
								onChange={(event) => {
									setPasswordWeak(!validatePassword(event.target.value));
									// setPassword(event.target.value);
								}}
							/>
						</FormControl>
					</Box>

					<Box>{passwordWeak && <PasswordHint variant="text" />}</Box>

					<Box>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={tryingReset}
							endIcon={<LockIcon />}
							disabled={passwordWeak}
							style={{ width: 300, marginTop: 20, marginBottom: 20 }}
						>
							Continue
						</LoadingButton>
					</Box>
				</Box>
				<div />

				<Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={0}>
					<Grid item xs={12}></Grid>
				</Grid>
			</Box>
		</form>
	);
}
