import { useCallback } from 'react';
import { get, startCase } from 'lodash-es';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Typography,
  Box,
  Divider,
  Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {FeatureToggle} from '../../../lib/featureToggles.tsx';
import { Appointment } from '../../../lib/type.ts';
import { useBreakpoints } from '../../../lib/hooks/useBreakpoints';
import { AppointmentTypeLabel } from '../../Booking/Form/Body/common/AppointmentType';

dayjs.extend(advancedFormat);

type AppointmentTableProps = {
  appointments: Appointment[];
  onClick: () => void;
  isHistory?: boolean;
}

export default function AppointmentTable(props: AppointmentTableProps) {
	const {appointments, onClick, isHistory = false} = props;

  // const router: NextRouter = useRouter();

  const handleLive = useCallback((_viewerId?: string, _appointmentId?: number) => {
    // router.push(`/video/${viewerId}/${appointmentId}`);
  }, []);

  const { isSM } = useBreakpoints();

  return (
    <>
      {isSM ? (
        <Stack direction={'column'}>
          {appointments?.map((row) => (
            <Box>
              <Typography>
                <Box component="span" fontWeight="bold">
                  Doctor:
                </Box>
                {` ${row.user}`}
              </Typography>
              <Typography>
                <Box component="span" fontWeight="bold">
                  Date:
                </Box>
                {` ${dayjs(row?.dateTime).format('MMMM Do YYYY  h:mm a')}`}
              </Typography>
              <Typography>
                <Box component="span" fontWeight="bold">
                  Visit Reason:
                </Box>
                {/*{row.appointmentType ? AppointmentTypeLabel[row.appointmentType] : ''}*/}
              </Typography>
              {isHistory ? (
                <FeatureToggle.If notEnabled feature="configure_patient_appointment_hide_followup">
                  <Button fullWidth color="primary" variant="outlined" onClick={onClick} sx={{ mt: 2 }}>
                    FOLLOW UP
                  </Button>
                </FeatureToggle.If>
              ) : (
                <FeatureToggle.If notEnabled feature="configure_patient_appointment_hide_join">
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={!row.viewerId}
                    onClick={() => handleLive(row.viewerId, row.id)}
                    sx={{ mt: 2 }}
                  >
                    JOIN SESSION
                  </Button>
                </FeatureToggle.If>
              )}
              <Divider sx={{ margin: 2 }} />
            </Box>
          ))}
        </Stack>
      ) : (
        <Box>
          <Table aria-label="appointments table">
            <TableHead>
              <TableRow>
                <TableCell>Doctor</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Visit Reason</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments?.map((row) => (
                <TableRow key={`${row.user}-${row.dateTime}`}>
                  <TableCell>{row.user}</TableCell>
                  <TableCell>{dayjs(row?.dateTime).format('MMMM Do YYYY  h:mm a')}</TableCell>
                  <TableCell>
                    {get(AppointmentTypeLabel, row.appointmentType, startCase(row.appointmentType ?? '--'))}
                  </TableCell>
                  <TableCell align="right">
                    {isHistory ? (
                      <FeatureToggle.If notEnabled feature="configure_patient_appointment_hide_followup">
                        <Button color="primary" variant="outlined" onClick={onClick}>
                          FOLLOW UP
                        </Button>
                      </FeatureToggle.If>
                    ) : (
                      <FeatureToggle.If notEnabled feature="configure_patient_appointment_hide_join">
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!row.viewerId}
                          onClick={() => handleLive(row.viewerId, row.id)}
                        >
                          JOIN SESSION
                        </Button>
                      </FeatureToggle.If>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  );
}

