import axios from 'axios';

export async function getVideoURL(id: string, code: string) {
	const apiURL = `${import.meta.env.VITE_APIV2}/apiv2/pt/videos/${id}?code=${code}`;

	try {
		const data = await axios.get(apiURL, { withCredentials: true });

		return data;
	} catch (error) {
		console.error(error);
	}
}
