import * as Sentry from "@sentry/react";
import { Outlet, createRoute, Navigate } from '@tanstack/react-router';
import {z} from 'zod';

import { NavBar } from '../components/Navigation/NavBar.tsx';
import { useAuthStore } from '../lib/context/useAuthStore.ts';
import { loginRoute } from '../pages/auth/route.ts';
// import {navigations} from './navigations.tsx';

import { rootRoute } from './rootRoute.tsx';

export const authRootRoute = createRoute({
	id: '_auth',
	// loader
	getParentRoute: () => rootRoute,
	validateSearch: z.object({
		id: z.string().optional().nullable(),
	}).parse,
	beforeLoad: async ({ search }) => {
		Sentry.addBreadcrumb({
			type: 'route:beforeLoad',
			level: 'debug',
			message: 'auth session '
		});

		const isLoginPage = !!location.href.match(/^\/login/i);

		console.log('root-route', 'before load', {isLoginPage, location});

		Sentry.addBreadcrumb({
			type: 'route:beforeLoad',
			level: 'debug',
			message: 'auth session location '
		}, {
			isLoginPage, location
		});

		const hasSearchId = !!search.id;
		const hasAccessToken = useAuthStore.getState().loginToken();

		Sentry.addBreadcrumb({
			type: 'route:beforeLoad',
			level: 'debug',
			message: 'auth session: hasAccessToken '
		}, {
			hasSearchId,
			hasAccessToken
		});

		// If the user is logged out, redirect them to the login page
		if (!hasAccessToken && !isLoginPage && !hasSearchId) {
			console.log('root-route', 'before load', 'redirecting..');

			Sentry.addBreadcrumb({
				type: 'route:beforeLoad',
				level: 'debug',
				message: 'auth session: redirecting to login page'
			}, {
				hasSearchId,
				hasAccessToken,
				isLoginPage,
			});

			useAuthStore.getState().logout();

			window.redirect('/login')
		}
	},
})

export const authWithNavRoute = createRoute({
	id: '_nav',
	component: AuthLayout,
	// loader
	getParentRoute: () => authRootRoute,
	beforeLoad: async () => {
		const navigations = await import('./navigations.tsx').then(({navigations}) => navigations);

		return {
			navigations,
		};
	}
});

function AuthLayout() {
	const { navigations } = authWithNavRoute.useRouteContext ();
	const { isLoggedIn } = useAuthStore();

	if (!isLoggedIn) {
		return <Navigate to={loginRoute.path} />;
	}

	return (
		<NavBar navigations={navigations}>
			<Outlet />
		</NavBar>
	);
}
