import styles from '../../styles/ThankYou.module.css';

export function ThankYou() {
	return (
		<>
			<div className={styles.header}>
				<div className={styles.headerThankyou}>Thank You!</div>

				<br />

				<div className={styles.headerText}>
					We'll be in touch shortly with available times for an appointment with one of our MD's
					<br />
					All consultations are free of charge for patients through OHIP coverage.
				</div>
			</div>

			<svg style={{ marginTop: '-2em', zIndex: 1 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
				<defs>
					<linearGradient id="gradient">
						<stop offset="0%" stopColor="#0700DD" />
						<stop offset="89%" stopColor="#00F2FF" />
					</linearGradient>
				</defs>
				<path
					fill="url(#gradient)"
					fillOpacity="1"
					d="M0,128L60,117.3C120,107,240,85,360,90.7C480,96,600,128,720,149.3C840,171,960,181,1080,181.3C1200,181,1320,171,1380,165.3L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
				/>
			</svg>

			<div className={styles.billSignature}>
				Dr. William Cherniak, MD, MPH,
				<br />
				CEO and Co-Founder
			</div>

			<div className={styles.contentContainer}>
				<div className={styles.contentGutter} />

				<div>
					<div className={styles.content}>
						<h1 className={styles.aboutTitle}>About Rocket Doctor</h1>
					</div>

					<div className={styles.content}>
						We are a community of fully-licensed board-certified doctors ready to provide virtual care 24/7. We want to
						make the process of receiving healthcare more peaceful, more accurate, and more convenient.
					</div>

					<div className={styles.content}>
						At the touch of a button, you’re able to schedule an appointment and speak to a doctor face-to-face to get
						important diagnosis, prescriptions and care instructions so you or your child can start to feel better,
						faster.
					</div>
				</div>

				<div className={styles.contentGutter} />
			</div>
		</>
	);
}
