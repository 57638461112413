import { createRoute } from '@tanstack/react-router';

import { rootRoute } from '../../router/rootRoute.tsx';

import { ThankYou } from './thankyou.tsx';

export const thankyouRoute = createRoute({
	path: '/thankyou',
	component: ThankYou,
	getParentRoute: () => rootRoute,
});
