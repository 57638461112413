import { useState, MouseEvent, useCallback, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import dayjs from 'dayjs';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import {
	Box,
	Button,
	IconButton,
	Stack,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Menu,
	MenuItem,
} from '@mui/material';

import { get } from '../../lib/api/_helper';
import UploadFileDialog from '../../components/UploadFile';
import { useBreakpoints } from '../../lib/hooks/useBreakpoints';
import { useGetDocuments } from '../../lib/hooks/useGetDocuments';
import { FeatureToggle } from '../../lib/featureToggles';

type DocumentViewProps = {
	appointmentId?: string;
	onClose?: () => void;
}

async function downloadBoxFile(fileUploadId: string | number) {
	try {
		const { boxUrl } = await get(`apiv2/pt/medicalForms/${fileUploadId}/downloadLink`);
		window.redirect(boxUrl)
	} catch (error) {
		console.log(error);
	}
}

function viewBoxFile(fileUploadId: string | number) {
	window.open(`/file/${fileUploadId}`);
}

function useMoreMenuState() {
	const [anchorEl, setAnchorEl] = useState(null);

	// @ts-ignore
	const handleClick = useCallback(({ currentTarget }: MouseEvent<HTMLButtonElement>) => setAnchorEl(currentTarget), [
		setAnchorEl,
	]);

	const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

	return {
		buttonElm: anchorEl,
		onOpenMenu: handleClick,
		onCloseMenu: handleClose,
		isOpen: Boolean(anchorEl),
	};
}

function DocumentMoreActions(props: any) {
	const { row } = props;

	const { isOpen, buttonElm, onOpenMenu, onCloseMenu } = useMoreMenuState();

	return (
		<>
			<IconButton aria-label="more" aria-controls="actions-menu" aria-haspopup="true" onClick={onOpenMenu}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				keepMounted={false}
				id={`actions-menu-${row.fileUpload.id}`}
				onClose={onCloseMenu}
				anchorEl={buttonElm}
				open={isOpen}
			>
				<MenuItem
					disabled={!row.fileUpload.id}
					date-file-id={row.fileUpload.id ?? 'none'}
					onClick={() => viewBoxFile(row.fileUpload.id)}
				>
					View
				</MenuItem>
				<MenuItem
					disabled={!row.fileUpload.id}
					date-file-id={row.fileUpload.id ?? 'none'}
					onClick={() => downloadBoxFile(row.fileUpload.id)}
				>
					Download
				</MenuItem>
			</Menu>
		</>
	);
}

function TableView({ documents }: any) {
	return (
		<Box>
			<TableContainer>
				<Table aria-label="pending appointments table">
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Date</TableCell>
							<TableCell align="center">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{documents?.map?.((row: any) => (
							<TableRow key={`${row.id}-${row.createdAt}`}>
								<TableCell>{`${row.fileUpload?.fileName || 'Unknown File'}`}</TableCell>
								<TableCell>
									{`${row.formType} - by ${
										row.fileUpload?.user?.fullTitleName ? `${row.fileUpload?.user?.fullTitleName}` : `Patient`
									}`}
								</TableCell>
								<TableCell>{dayjs(row.createdAt).format('MM/DD/YYYY  h:mm a')}</TableCell>
								<TableCell align="right">
									<DocumentMoreActions row={row} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}

function DocListItem(props: any) {
	const { row } = props;

	return (
		<ListItem alignItems="flex-start">
			<Stack direction="row" width="100%" justifyContent="space-between">
				<ListItemText>
					<Stack direction="column">
						<span>
							<strong>Name: </strong>
							<span>{row.fileUpload?.fileName || 'Unknown File'}</span>
						</span>
						<span>
							<strong>Type: </strong>
							<span>{row.formType}</span>
						</span>
						<span>
							<strong>Date: </strong>
							<span>{dayjs(row.createdAt).format('MM/DD/YYYY  h:mm a')}</span>
						</span>
					</Stack>
				</ListItemText>

				<Box>
					<DocumentMoreActions row={row} />
				</Box>
			</Stack>
		</ListItem>
	);
}

function DocListView({ documents, viewBoxFile, downloadBoxFile }: any) {
	return (
		<Stack>
			<Stack mx={2} direction="row" justifyContent="space-between">
				<Typography>File</Typography>
				<Typography>Action</Typography>
			</Stack>
			<Divider />
			<List>
				{documents?.map?.((row: any) => (
					<DocListItem
						key={`${row.id}-${row.createdAt}`}
						downloadBoxFile={downloadBoxFile}
						viewBoxFile={viewBoxFile}
						row={row}
					/>
				))}
			</List>
		</Stack>
	);
}

export function DocumentView({ appointmentId, onClose }: DocumentViewProps) {
	const { isSM, isMD, isLG } = useBreakpoints();

	useEffect(() => {
		document.title = 'Patient Documents - Rocket Doctor';
	}, []);

	const [fileDialog, setFileDialog] = useState(false);

	const { data: documents } = useGetDocuments();

	return (
		<>
			<Box display="grid" mx={2} gap={2} gridTemplateColumns="repeat(4, 1fr)">
				<Stack direction="row" justifyContent="space-between" gridColumn="span 4" mt={2}>
					<Typography variant="h4" color="initial">
						Documents
					</Typography>

					<Button variant="outlined" onClick={onClose} hidden>
						Close
					</Button>
				</Stack>

				<Box gridColumn="span 4">
					{isLG ? (
						<TableView documents={documents} viewBoxFile={viewBoxFile} downloadBoxFile={downloadBoxFile} />
					) : null}

					{isMD || isSM ? (
						<DocListView documents={documents} viewBoxFile={viewBoxFile} downloadBoxFile={downloadBoxFile} />
					) : null}
				</Box>

				<Box gridColumn={isLG ? undefined : 'span 2'}>
					<FeatureToggle.If notEnabled feature="configure_patient_document_hide_add">
						<Button fullWidth variant="contained" onClick={() => setFileDialog(true)}>
							ADD DOCUMENT
						</Button>
					</FeatureToggle.If>
				</Box>
			</Box>

			<Box>
				<UploadFileDialog open={fileDialog} handleClose={() => setFileDialog(false)} appointmentId={appointmentId} />
			</Box>
		</>
	);
}

export default DocumentView;

export function Documents() {
	useEffect(() => {
		document.title = 'Patient Documents - Rocket Doctor';
	}, []);

	return (
		<DocumentView />
	);
}
