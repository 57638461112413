import { useNavigate } from '@tanstack/react-router';
import { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Button, Fab, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ChatIcon from '@mui/icons-material/Chat';
import CircularProgress from '@mui/material/CircularProgress';

import { useBreakpoints } from '../../lib/hooks/useBreakpoints.ts';
import { useGetPatient } from '../../lib/hooks/useGetPatient.ts';
import { useGetAppointment } from '../../lib/hooks/useGetAppointments.ts';
import DynamicAppointmentsView from '../Patient/Dashboard/AppointmentsView.tsx';

declare global {
	interface Window {
		zE: any;
		zESettings: any;
	}
}

const fabStyle = {
	position: 'absolute',
	bottom: '16px',
	right: '16px',
};

class Zendesk {
	static log(...args: any[]) {
		if (import.meta.env.VITE_SYSTEM_ENV === 'dev') {
			console.log('z', ...args);
		}
	}

	static zE(...args: any[]) {
		try {
			return window.zE(...args);
		} catch (e) {
			console.error('zendesk not initialized');
		}
	}

	static on(eventType: any, eventHandler: any) {
		return Zendesk.zE('webWidget:on', eventType, eventHandler);
	}

	static updateSettings(settings: any) {
		Zendesk.log('updateSettings', settings);

		Zendesk.zE('webWidget', 'updateSettings', settings);
	}

	static identify(userName: string, userEmail?: string) {
		Zendesk.log('identify', { userName, userEmail });

		Zendesk.zE('webWidget', 'identify', {
			name: userName,
			email: userEmail,
		});
	}

	static prefill(prefill: any) {
		Zendesk.log('prefill', prefill);

		Zendesk.zE('webWidget', 'prefill', {
			name: {
				readOnly: false,
				...Object.assign({}, prefill.name),
			},
			email: {
				readOnly: false,
				...Object.assign({}, prefill.email),
			},
			phone: {
				readOnly: false,
				...Object.assign({}, prefill.phone),
			},
		});
	}

	static selectDepartment(select: string, departments?: string[]) {
		Zendesk.log('selectDepartment', { select, departments });

		Zendesk.updateSettings({
			webWidget: {
				chat: {
					departments: {
						select: select,
						enabled: departments,
					},
				},
			},
		});
	}
}

export default function HomePage() {
	const navigate = useNavigate();

	const isChatOpen = useRef<boolean>();

	const [isOpiningChat, setIsOpiningChat] = useState<boolean>();

	const { isSM } = useBreakpoints();

	const { isValid: isPatientValid, data } = useGetPatient();

	const handleOnOpenProfile = useCallback(async () => {
		// @ts-ignore
		await navigate({ to: '/profile' });
	}, []);

	const handleOnChatToggle = useCallback(() => {
		setIsOpiningChat(true);

		const offset = {
			horizontal: fabStyle.right,
			vertical: fabStyle.bottom,
		};

		const position = {
			horizontal: 'right',
			vertical: 'bottom',
		};

		window.zESettings.offset = offset;
		window.zESettings.webWidget.offset = offset;
		window.zESettings.webWidget.position = {
			horizontal: 'left',
			vertical: 'top',
		};

		Zendesk.selectDepartment('Support for Patients', ['Support for Patients']);

		const userName = `${data?.firstName} ${data?.lastName}`;

		Zendesk.identify(userName, data?.email);

		Zendesk.prefill({
			name: {
				value: userName,
			},
			email: {
				value: data?.email,
			},
			phone: {
				value: data?.phoneNumber,
			},
		});

		Zendesk.updateSettings({
			offset,
			webWidget: {
				offset,
				position,
			},
		});

		setTimeout(() => {
			window?.zE?.activate?.({ hideOnClose: true });
		}, 10);
	}, [data, data?.email, data?.lastName, data?.firstName, data?.phoneNumber, setIsOpiningChat]);

	const handleOnChatEvent = useCallback(
		(evt: any) => {
			switch (evt.action) {
				case 'Web Widget Opened':
					isChatOpen.current = true;
					break;
				case 'Web Widget Minimised':
					isChatOpen.current = false;
					break;
				default:
					break;
			}

			setTimeout(() => setIsOpiningChat(false), 100);
		},
		[setIsOpiningChat],
	);

	useEffect(() => {
		Zendesk.on('userEvent', handleOnChatEvent);
	}, [handleOnChatEvent]);

	useEffect(() => {
		document.title = 'Welcome to Rocket Doctor';
	}, []); //html page title

	const ctaMessage = isPatientValid
		? `
    To get started, request and schedule an appointment by clicking the "Set up an appointment" button or the chat on the bottom right of this page.
    A member of our Patient Care team will be in touch with you soon.`
		: `
    To get started, please complete your personal profile to be able to schedule an appointment.
    After it is completed, return to this page to interact with a member of our Patient Care team to schedule an appointment.
    `;

	const { data: appointments, isLoading } = useGetAppointment();

	const hasAppointments = !isLoading && !!appointments?.length;

	return (
		<>
			{hasAppointments ? (
				<DynamicAppointmentsView appointments={appointments} isSM={isSM} onClick={handleOnChatToggle} />
			) : null}

			{!hasAppointments ? (
				<Box
					display="grid"
					width={isSM ? '100vw' : undefined}
					gridTemplateColumns={isSM ? 'auto' : '1fr max(610px) 1fr'}
					gridTemplateRows={isSM ? '60px 1fr' : 'auto'}
				>
					<span />

					<Stack
						gap={4}
						direction="column"
						margin={isSM ? 2 : 1}
						sx={{ maxWidth: isSM ? 'calc(100vw - 32px)' : undefined }}
					>
						<Typography variant="h3" fontWeight="bold" color="#092D82">
							Welcome to Rocket Doctor
						</Typography>

						<Typography variant="subtitle2" color="#092D82">
							{ctaMessage}
						</Typography>

						<Box
							gap={2}
							display="grid"
							gridTemplateRows={isSM ? '1fr 1fr' : '1fr'}
							gridTemplateColumns={isSM || !isPatientValid ? '1fr' : '1fr 1fr'}
						>
							<Button color="primary" variant="outlined" onClick={handleOnOpenProfile}>
								Edit Profile
							</Button>

							{isPatientValid ? (
								<LoadingButton variant="contained" loading={isOpiningChat} onClick={handleOnChatToggle}>
									Set up An Appointment
								</LoadingButton>
							) : null}
						</Box>

						<img src="/medicine.png" alt="chat" style={{ order: isSM ? 3 : 5 }} />
					</Stack>
					<span />
				</Box>
			) : null}

			{isPatientValid ? (
				<Fab variant="extended" color="primary" sx={fabStyle} onClick={handleOnChatToggle}>
					<ChatIcon />
					{isOpiningChat ? <CircularProgress size="20" /> : null}
				</Fab>
			) : null}
		</>
	);
}
