import { fileRoute } from '../pages/file/route.ts';
import { homeRoute } from '../pages/home/route.ts';
import { loginRoute, logoutRoute } from '../pages/auth/route.ts';
import { profileRoute } from '../pages/profile/route.ts';
import { thankyouRoute } from '../pages/thankyou/route.ts';
import { documentRoute } from '../pages/documents/route.ts';
import { passwordSetRoute } from '../pages/auth/password-set.tsx';
import { passwordResetRoute } from '../pages/auth/password-reset.tsx';
import { passwordForgotRoute } from '../pages/auth/password-forgot.tsx';
import { videoAppointmentRoute, videoRoute } from '../pages/video/route.ts';

import { authRootRoute, authWithNavRoute } from './authRoute.tsx';
import { rootRoute } from './rootRoute.tsx';

const authTree = authRootRoute.addChildren([
	videoRoute.addChildren([videoAppointmentRoute]),
	authWithNavRoute.addChildren([homeRoute, fileRoute, profileRoute, documentRoute]),
]);

const routeTree = rootRoute.addChildren([
	authTree,
	loginRoute,
	logoutRoute,
	thankyouRoute,
	passwordSetRoute,
	passwordResetRoute,
	passwordForgotRoute,
]);

export { routeTree };
