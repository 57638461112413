import { makeStyles } from '@mui/styles';

import Section from './Section';

const useStyles = makeStyles(() => ({
	marginBottom: {
		marginBottom: '50px',
	},
}));

export default function RDLogoDark() {
	const classes = useStyles();

	return (
		<Section className={classes.marginBottom} centered={true}>
			<img src="/RDLogoDark.png" alt="Rocket Doctor logo" width="300" height="57" />
		</Section>
	);
}
