// @ts-nocheck
import { memo, useCallback } from 'react';

import ChildrenProps from '../../../../../lib/type.ts';

import { Grid, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles';

import SectionErrorLabel from './ErrorLabel';
import useMediaQuery from "@mui/material/useMediaQuery";
import SectionTitle from './Title';

interface BodySectionProps extends ChildrenProps {
  title: string;
  firstLabelName: string;
  required?: boolean;
  extraSpacing?: boolean;
  errorMessage?: string;
}

const useStyles = makeStyles(() => ({
  sectionContainer: {
    padding: '8px',
    borderRadius: 3,
  },
  alertContainer: {
    '&  .MuiGrid-item': {
      padding: 0,
    },
  },
  alertColor: {
    backgroundColor: '#FFD6D6',
  },
}));

const BodySection = memo((props: BodySectionProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches: boolean = useMediaQuery(theme.breakpoints.down('xs'));

  const { title, firstLabelName, required, children, errorMessage, extraSpacing } = props;

  const getAlertColor = useCallback(() => {
    if (errorMessage) {
      return classes.alertColor;
    }
  }, [errorMessage]);

  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={extraSpacing && !matches ? 3 : 1}
        className={`${classes.sectionContainer} ${getAlertColor()}`}
      >
        <Grid item xs={12}>
          <SectionTitle title={title} firstLabelName={firstLabelName} required={required} />
        </Grid>
        {children}
        {required && (
          <Grid item className={classes.alertContainer}>
            <SectionErrorLabel name={firstLabelName} error={errorMessage !== undefined} message={errorMessage} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default BodySection;
