import { useLayoutEffect, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { makeStyles, createStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CSSObject, styled, Theme } from '@mui/material/styles';

import { Drawer as NavDrawerMenu } from './Drawer.tsx';
import { useBreakpoints } from '../../lib/hooks/useBreakpoints.ts';
import { useNavbarStore } from './useNavbar.ts';

const drawerWidth = 225;

const drawerBaseMixin = () => ({
	backgroundColor: '#002b87',
	color: '#fff',
});

const openedMixin = (theme?: Theme, isSM?: boolean): CSSObject => ({
	...drawerBaseMixin(),
	width: isSM ? '70vw' : drawerWidth,
	transition: theme?.transitions?.create?.('width', {
		easing: theme?.transitions?.easing?.sharp,
		duration: theme?.transitions?.duration?.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme?: Theme): CSSObject => ({
	...drawerBaseMixin(),
	transition: theme?.transitions.create('width', {
		easing: theme?.transitions.easing.sharp,
		duration: theme?.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme?.spacing?.(7)} + 1px)`,
	[theme?.breakpoints?.up?.('sm') ?? 'sm']: {
		width: `calc(${theme?.spacing?.(8)} + 1px)`,
	},
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			[theme?.breakpoints?.up('sm')]: {
				//display: 'none',
				width: `calc(100% - ${drawerWidth}px)`,
				marginLeft: drawerWidth,
			},
		},
		menuButton: {
			marginRight: theme?.spacing?.(2),
			[theme?.breakpoints?.up?.('sm')]: {
				display: 'none',
			},
		},
		// necessary for content to be below app bar
		// @ts-ignore
		toolbar: {
			...(theme?.mixins?.toolbar ?? {}),
			margin: '10px',
		},
		drawerPaper: {
			width: drawerWidth,
			backgroundColor: '#002b87',
			color: '#fff',
		},
		content: {
			flexGrow: 1,
			padding: theme?.spacing?.(3),
		},
	}),
);

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'isCollapsed' && prop !== 'isSM' })(
	({ theme, isCollapsed, isSM }: { isSM?: boolean; isCollapsed?: boolean; theme?: Theme }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(!isCollapsed && {
			...openedMixin(theme, isSM),
			'& .MuiDrawer-paper': openedMixin(theme, isSM),
		}),
		...(isCollapsed && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
		backgroundColor: 'transparent',
	}),
);

type NavBarProps = PropsWithChildren<{
	navigations: any[],
}>;

export function NavBar({ children, navigations }: NavBarProps) {
	const classes = useStyles();
	const { isSM } = useBreakpoints();

	const {
		isMinimized,
		isMaximized,
		isDrawerCollapse,
		unsetDrawerStatus,
		toggleDrawerStatus,
		setToggleStatusSet,
	} = useNavbarStore();

	useLayoutEffect(() => {
		if (isSM) {
			setToggleStatusSet(['collapsed', 'maximized']);
			unsetDrawerStatus('maximized');
		} else {
			setToggleStatusSet(['maximized', 'minimized']);
			unsetDrawerStatus('collapsed');
		}
	}, [isSM, setToggleStatusSet, unsetDrawerStatus]);

	const gridTemplateColumns = isSM ? `1fr` : `${isMinimized ? 65 : drawerWidth}px 1fr`;

	const gridTemplateRows = isSM ? '60px auto' : 'auto';

	return (
		<Box
			gap={0}
			display="grid"
			id="navbar-layout"
			gridTemplateRows={gridTemplateRows}
			gridTemplateColumns={gridTemplateColumns}
		>
			{isSM ? (
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleDrawerStatus}
							className={classes.menuButton}
						>
							<MenuIcon />
						</IconButton>

						<img src="/RDLogoDark.png" alt="Rocket Doctor logo" width="182" height="37" />
					</Toolbar>
				</AppBar>
			) : null}

			<Drawer
				isSM={isSM}
				open={isMaximized}
				isCollapsed={isMinimized}
				onClose={toggleDrawerStatus}
				variant={isSM || isDrawerCollapse ? 'temporary' : 'permanent'}
			>
				<Stack direction="column" sx={{ height: '100%' }}>
					<Box
						display="flex"
						mx={2}
						mt={2}
						flexDirection={isMinimized ? 'column' : 'row'}
						justifySelf="center"
						alignContent="center"
					>
						{isSM ? null : isMinimized ? (
							<img src="/RDLogoDarkGraphic.png" alt="Rocket Doctor logo" width="32" height="45" />
						) : (
							<img src="/RDLogoDark.png" alt="Rocket Doctor logo" width="158" height="34" />
						)}

						{isMinimized && !isSM ? (
							<IconButton color="inherit" onClick={toggleDrawerStatus}>
								<MenuIcon />
							</IconButton>
						) : null}

						{!isMinimized && !isSM ? (
							<IconButton color="inherit" onClick={toggleDrawerStatus}>
								<ChevronLeftIcon />
							</IconButton>
						) : null}
					</Box>

					<NavDrawerMenu
						navigations={navigations}
						isCollapse={isDrawerCollapse}
					/>
				</Stack>
			</Drawer>

			<Box pt="60px">{children}</Box>
		</Box>
	);
}
