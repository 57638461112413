import { z } from 'zod';
import { createRoute } from '@tanstack/react-router';

import { useAuthStore } from '../../lib/context/useAuthStore.ts';
import { rootRoute } from '../../router/rootRoute.tsx';

import { Login } from './login.tsx';

export const loginRoute = createRoute({
	path: '/login',
	component: Login,
	getParentRoute: () => rootRoute,
	validateSearch: z.object({
		redirect: z.string().optional(),
	}),
});

export const logoutRoute = createRoute({
	path: '/logout',
	beforeLoad: async () => {
		await useAuthStore.getState().logout();
	},
	getParentRoute: () => rootRoute,
});
