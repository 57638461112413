import { QueryClient, useQuery } from 'react-query';
import { dehydrate } from 'react-query/hydration';
import { get } from '../api/_helper';
import { Appointment } from '../type.ts';

const url = `apiv2/pt/patients/appointments`;

export const useGetAppointment = () => {
  return useQuery<Appointment[]>('appointments', () => get(url));
};

export const useGetPrefetchAppointment = async (Cookie = {}) => {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery<Appointment[]>('appointments', () => get(url, {
    headers: { Cookie }
  }) ?? []);

  return dehydrate(queryClient);
};
