import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {List, ListItemButton, ListItemIcon, ListItemText, Stack} from '@mui/material';
import { ReactElement, ReactNode } from 'react';

type NavItemProps = {
	IconComponent: ReactElement | ReactNode | any;
	isCollapse?: boolean;
	onClick?: any;
	label: string;
	path: string;
};

function NavItem(props: NavItemProps) {
	const { path, isCollapse, IconComponent, label } = props;

	return (
		<ListItemButton
			sx={{
				px: 2.5,
				minHeight: 48,
				color: 'inherit',
				justifyContent: isCollapse ? 'center' : 'initial',
			}}
			onClick={() => window.redirect(path)}
		>
			<ListItemIcon
				sx={{
					minWidth: 0,
					color: 'inherit',
					justifyContent: 'center',
					mr: isCollapse ? 'auto' : 3,
				}}
			>
				<IconComponent />
			</ListItemIcon>
			<ListItemText primary={label} sx={{ opacity: isCollapse ? 0 : 1 }} />
		</ListItemButton>
	);
}

export function Drawer(props: { isCollapse: boolean; navigations: any[] }) {
	const navItems = props.navigations?.map((item) => {
		if (item?.hidden) {
			return null;
		}

		const { IconComponent, label, path } = item;

		return (
			<NavItem
				key={path + label}
				path={path}
				label={label}
				isCollapse={props?.isCollapse}
				IconComponent={IconComponent}
			/>
		);
	});

	return (
		<Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
			<List>{navItems}</List>

			<List>
				<NavItem label="Logout" path="/logout" IconComponent={ExitToAppIcon} isCollapse={props?.isCollapse} />
			</List>
		</Stack>
	);
}
