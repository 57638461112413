import { get } from 'lodash-es';
import { SyntheticEvent, useCallback, useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
	createStyles,
	makeStyles,
} from '@mui/styles';
import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';


import { uploadFile as uploadFileAPI } from '../../lib/api/uploadFile';
import { FormType, ToastSeverity } from '../../lib/type.ts';
import { ToastContext } from '../../lib/context/ToastContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme?.spacing?.(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

type UploadFileDialogProps = {
  open: boolean;
  handleClose: () => void;
  appointmentId?: string;
}

export default function UploadFileDialog({ open, handleClose, appointmentId }: UploadFileDialogProps) {
  const queryClient = useQueryClient();
  const classes = useStyles();

  const [file, setFile] = useState<File>();
  const [formType, setFormType] = useState<FormType>(FormType.MANUAL_FORM);
  const [base64URL, setBase64URL] = useState<string | ArrayBuffer | null>('');

  const toastContext = useContext(ToastContext);
  const { mutateAsync: uploadFile, isLoading } = useMutation(
    async () =>
      await uploadFileAPI({
        base64URL: base64URL as string,
        fileName: file?.name ?? 'file',
        url: appointmentId ? `appointments/${appointmentId}/file` : `file`,
        formType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('documents');
        toastContext.genericToastFn({ open: true, severity: ToastSeverity.SUCCESS, text: 'Upload successful' });
        close();
      },
      onError: (err) => {
        toastContext.genericToastFn({ open: true, severity: ToastSeverity.ERROR, text: 'Failure please try again' });
        console.log(err);
      },
    },
  );

  const getBase64 = async (file: Blob): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve) => {
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load something...
      reader.onload = () => resolve(reader.result);
    });
  };

  const handleFileInputChange = async (e: SyntheticEvent<EventTarget>) => {
    const Newfile = (e.target as HTMLFormElement).files[0];
    const result = await getBase64(Newfile);

    setFile(Newfile);
    setBase64URL(result);
  };

  const close = useCallback(() => {
    setFile(undefined);
    setBase64URL(null);
    handleClose();
  }, [handleClose]);

  return (
    <Dialog open={open} maxWidth={'xs'} onClose={close} aria-labelledby="form-dialog-title">
      <DialogContent>
        <Button variant="contained" component="label">
          ADD ATTACHMENT
          <input type="file" name="file" onChange={handleFileInputChange} hidden />
        </Button>
        <DialogContentText>{file?.name} </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="elect-label">File Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formType}
            label="Age"
            onChange={(event) => setFormType(event.target.value as FormType)}
          >
            {Object.keys(FormType).map((key) => (
              <MenuItem key={key} value={get(FormType, key)}>{get(FormType, key)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button onClick={close}>
            CANCEL
          </Button>
          <Button color="primary" disabled={!file && !base64URL} onClick={() => uploadFile()}>
            ADD
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
