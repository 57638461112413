import { makeStyles } from '@mui/styles';
import { getRouteApi } from '@tanstack/react-router';
import { useState, useEffect, useCallback, useRef } from 'react';
import DailyIframe, { DailyCallOptions } from '@daily-co/daily-js';
import { Button, Box, Drawer } from '@mui/material';

import { get } from '../../lib/api/_helper.ts';
import RDLogoDark from '../../components/Common/RDLogoDark.tsx';
import Documents from '../../components/Documents/DocumentView.tsx';

let callFrame;

const useStyles = makeStyles(() => ({
	text: {
		color: 'white',
		padding: '16px',
		textAlign: 'center',
	},
	background: {
		minHeight: '100vh',
		backgroundColor: '#002B87',
	},
	documentButton: {
		position: 'fixed',
		width: '100vw',
		margin: 0,
		bottom: 0,
		left: 0,
	},
	logo: {
		padding: '10% 40px 40px 40px',
	},
	input: {
		backgroundColor: 'white',
		color: 'black',
		border: 'white',
		outline: 'white',
	},
}));

const Route = getRouteApi('/_auth/video/$viewerId/$appointmentId');

export default function VideoAppointment() {
	const parentElRef = useRef<HTMLDivElement>(null);
	const { viewerId, appointmentId } = Route.useParams();

	const classes = useStyles();

	const [step, _setStep] = useState(0);
	const [uploadDrawer, setUploadDrawer] = useState(false);

	const startVisit = useCallback(async () => {
		try {
			const { roomId } = await get(`apiv2/pt/videos/${viewerId}/authenticated`);

			const config: DailyCallOptions = {
				showLeaveButton: true,
				iframeStyle: {
					position: 'fixed',
					height: 'calc(100vh - 36px)',
					width: '100vw',
					zIndex: '10',
					padding: '0',
					border: '0',
					margin: '0',
					left: '0',
					top: '0',
				},
			};

			callFrame = DailyIframe.createFrame(config);

			const url = `${import.meta.env.VITE_DAILY_BASE_URL}/${roomId}`;

			callFrame.join({ url });
		} catch (error) {
			console.error('callFrame', error);
		}
	}, []);

	useEffect(() => {
		startVisit()
			.then(() => console.log('start visit successful'))
			.catch((error) => console.error('start visit failed', error));
	}, [startVisit]);

	// let container;

	// useEffect(() => {
	// 	container = window !== undefined ? () => window?.document.body : undefined;
	// });

	return (
		<Box display="grid" position="absolute" top="0" left="0" width="100vw" height="100vh" bgcolor="#002B87">
			<div className={classes.logo} ref={parentElRef}>
				<RDLogoDark />
			</div>

			{step === 0 && (
				<Box display="grid" justifyContent="flex-end" alignItems="center" zIndex={100}>
					<Box className={classes.documentButton}>
						<Button
							variant="contained"
							onClick={() => setUploadDrawer(!uploadDrawer)}
							style={{
								backgroundColor: '#04AFDE',
								borderRadius: '0',
								color: 'black',
								width: '100vw',
							}}
						>
							Documents
						</Button>
					</Box>

					<Box aria-label="mailbox folders">
						{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
						<Drawer
							anchor="bottom"
							variant="temporary"
							open={uploadDrawer}
							container={window.document.body}
							onClose={() => setUploadDrawer(!uploadDrawer)}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile
							}}
						>
							<div style={{ height: '60vh' }}>
								<Documents appointmentId={appointmentId} onClose={() => setUploadDrawer(!uploadDrawer)} />
							</div>
						</Drawer>
					</Box>
				</Box>
			)}

			{step === 1 && <div className={classes.text}> Thank you for using Rocket Doctor! </div>}
		</Box>
	);
}
