import { LinearProgress } from '@mui/material';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { Outlet, useRouterState, createRootRoute } from '@tanstack/react-router';

function RouterLoading() {
	const isLoading = useRouterState({ select: (s) => s.status === 'pending' });

	return isLoading ? (
		<LinearProgress
			style={{
				top: '0px',
				left: '0px',
				height: '4px',
				width: '100vw',
				position: 'absolute',
			}}
		/>
	) : null;
}

function RootComponent() {
	return (
		<>
			<RouterLoading />
			<Outlet />
			{['localhost', 'dev'].includes(import.meta.env.VITE_SYSTEM_ENV) && <TanStackRouterDevtools position="bottom-right" />}
		</>
	);
}

export const rootRoute = createRootRoute({
	component: RootComponent,
});
