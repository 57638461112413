import { createMuiTheme, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, ErrorComponent, createRouter } from '@tanstack/react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import './sentry.ts';
import CustomToast from './components/Common/CustomToast.tsx';
import { ToastContext } from './lib/context/ToastContext.ts';
import { FeatureToggleProvider } from './lib/featureToggles.tsx';
import { ToastSeverity, ToastState } from './lib/type.ts';

import { routeTree } from './router/routeTree.ts';

window.global = window;

const router = createRouter({
	routeTree,
	defaultPreload: 'intent',
	defaultErrorComponent: ({ error }: any) => <ErrorComponent error={error} />,
	defaultPendingComponent: () => <div className={`p-2 text-2xl`}>Loading...</div>,
});

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}

declare global {
	interface Window {
		redirect: (to: string) => void;
	}
}

const baseTheme = {
	palette: {
		primary: {
			main: '#002b87',
		},
	},
};

const theme = createMuiTheme(baseTheme);
const muiTheme = createTheme(baseTheme);

function App() {
	const queryClient = new QueryClient();

	const [toastState, setToastState] = useState<ToastState>({ open: false, severity: ToastSeverity.INFO, text: '' });

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<MuiThemeProvider theme={muiTheme}>
						<ToastContext.Provider value={{ genericToastFn: setToastState }}>
							<RouterProvider router={router} defaultPreload="intent" />
							<CustomToast
								text={toastState.text}
								show={toastState.open}
								severity={toastState.severity}
								onClose={() => setToastState({ ...toastState, open: false })}
							/>
						</ToastContext.Provider>
					</MuiThemeProvider>
				</ThemeProvider>
				{['localhost', 'dev'].includes(import.meta.env.VITE_SYSTEM_ENV) ? (
					<ReactQueryDevtools initialIsOpen={false} />
				) : null}
			</QueryClientProvider>
		</LocalizationProvider>
	);
}

window.redirect = function locationRedirect(to: string) {
	console.log('redirect', 'full-reload', { to, from: window.location });
	// @ts-ignore
	window.location = to;
};

createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<FeatureToggleProvider>
			<SnackbarProvider />
			<App />
		</FeatureToggleProvider>
	</React.StrictMode>,
);

// @ts-ignore
window.routeTree = routeTree;
