import ChildrenProps from '../../../../../../lib/type.ts';

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 500,
    fontFamily: 'Inter, sans-serif',
  },
  asterisk: {
    color: '#f23a3c',
    marginLeft: '4px',
    fontSize: '16px',
  },
}));

interface SectionTitleProps extends ChildrenProps {
  title: string;
  firstLabelName: string;
  required?: boolean;
}

const SectionTitle = (props: SectionTitleProps) => {
  const classes = useStyles();

  const { title, firstLabelName, required } = props;

  return (
    <label className={classes.label} htmlFor={firstLabelName}>
      {required ? (
        <>
          <span>{title}</span>
          <span className={classes.asterisk}> * </span>
        </>
      ) : (
        <span>{title}</span>
      )}
    </label>
  );
};

export default SectionTitle;
