import {
	insuranceTypeCodeCA,
} from '../lib/type';


export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const validatePassword = (password: string) => {
  const re = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{10,}$/;
  return re.test(String(password));
};

export const isUS = import.meta.env.VITE_SYSTEM_ENV === 'us';
export const NONE_VALUE = 'none';


export const mapInsuranceType = (
  insuranceType: string | undefined | null
): insuranceTypeCodeCA | typeof NONE_VALUE => {

  if (!insuranceType) return NONE_VALUE;

  const normalizedType = insuranceType.trim().toUpperCase();

  const typeWithoutParens = normalizedType.replace(/\s*\(.*?\)\s*/g, '').trim();

  const mapping: Record<string, insuranceTypeCodeCA> = {

    'MSP': insuranceTypeCodeCA.MSP,
    'BRITISH COLUMBIA MEDICAL SERVICES PLAN': insuranceTypeCodeCA.MSP,
    'BC MEDICAL SERVICES PLAN': insuranceTypeCodeCA.MSP,
    'MEDICAL SERVICES PLAN': insuranceTypeCodeCA.MSP,
    'BCMSP': insuranceTypeCodeCA.MSP,


    'OHIP': insuranceTypeCodeCA.OHIP,
    'ONTARIO HEALTH INSURANCE PLAN': insuranceTypeCodeCA.OHIP,
    'ON HEALTH INSURANCE': insuranceTypeCodeCA.OHIP,

    'ACHIP': insuranceTypeCodeCA.ACHIP,
    'ALBERTA HEALTH CARE INSURANCE PLAN': insuranceTypeCodeCA.ACHIP,
    'AB HEALTH CARE': insuranceTypeCodeCA.ACHIP,
    'ALBERTA HEALTH': insuranceTypeCodeCA.ACHIP,
    'AHCIP': insuranceTypeCodeCA.ACHIP,

    'NBM': insuranceTypeCodeCA.NBM,
    'NEW BRUNSWICK MEDICARE': insuranceTypeCodeCA.NBM,
    'NB MEDICARE': insuranceTypeCodeCA.NBM,
    'NEW BRUNSWICK HEALTH': insuranceTypeCodeCA.NBM,
  };

  if (mapping[typeWithoutParens]) {
    return mapping[typeWithoutParens];
  }

  for (const [key, value] of Object.entries(mapping)) {
   
    if (key.length <= 3) continue;

    if (typeWithoutParens.includes(key) || key.includes(typeWithoutParens)) {
      return value;
    }
  }

  return NONE_VALUE;
};


export const isInsuranceTypeCodeCA = (type: string | undefined): type is insuranceTypeCodeCA => {
  return Object.values(insuranceTypeCodeCA).includes(type as insuranceTypeCodeCA);
};
