import * as yup from 'yup';

import { GENDERS, PRONOUNS, PROVINCE, STATES, SEX } from '../type.ts';

export const patientValidationSchema = yup.object().shape({
  /******************
   * personal
   */
  firstName: yup.string().typeError('first name is required').required('first name is required'),
  lastName: yup.string().typeError('last name is required').required('last name is required'),
  sex: yup.string().typeError('sex is required').oneOf(Object.values(SEX)).required('sex at birth is required'),
  dob: yup.date().typeError('date for birth is required').required('date for birth is required'),
  /**
   * personal optional
   * ****************/
  gender: yup.string().nullable().oneOf(Object.keys(GENDERS)).optional(),
  pronouns: yup.string().oneOf(Object.keys(PRONOUNS)).optional(),
  /******************
   * contact
   */
  email: yup.string().typeError('email is required').email('must be a valid email').required('email is required'),
  phoneNumber: yup
    .string()
    .matches(/\d+/, { excludeEmptyString: true, message: 'phone number may have digits only' })
    .typeError('phone number is required')
    .required('phone number is required'),
  address1: yup.string().typeError('address is required').required('address is required'),
  city: yup.string().typeError('city is required').required('city is required'),
  state: yup
    .string()
    .typeError('province is required')
    .oneOf(Object.values({ ...PROVINCE, ...STATES }))
    .required('province is required'),
  postalCode: yup
    .string()
    .typeError('postal code is required')
    .uppercase()
    .matches(/^(?!\s*$).+/, 'must be a valid post code')
    .required('postal code is required'),
  /**
   * contact optional
   * ****************/
  address2: yup.string().nullable().optional(),
  preferredPharmacy: yup.object().nullable().optional(),
});

export const patientRequiredValidationSchema = patientValidationSchema.omit([
  'gender',
  'pronouns',
  'address2',
  'preferredPharmacy',
]);
