import LockIcon from '@mui/icons-material/Lock';
import { makeStyles } from '@mui/styles';
import { createRoute } from '@tanstack/react-router';
import { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Grid, Typography, TextField, FormControl, LinearProgress, List, ListItem } from '@mui/material';

import RDLogoLight from '../../components/Common/RdLogoLight.tsx';
import { ToastContext } from '../../lib/context/ToastContext.ts';
import { validatePassword } from '../../lib/_helpers.ts';
import { ToastSeverity } from '../../lib/type.ts';
import { post } from '../../lib/api/_helper.ts';
import { rootRoute } from '../../router/rootRoute.tsx';
import { loginRoute } from './route.ts';

export const passwordSetRoute = createRoute({
	component: PasswordSet,
	path: '/password-set',
	getParentRoute: () => rootRoute,
});

const useStyles = makeStyles(() => ({
	logo: { marginTop: 50, textAlign: 'center' },
	textPlusMargin: { marginTop: 0, fontSize: '1.8rem', marginBottom: 15, textAlign: 'center' },
	h6: { fontSize: '1.0rem', textAlign: 'left' },
	width300: { width: '300px' },
	gridMargin: { width: 300, margin: 'auto' },
	submitBtn: { width: 300, margin: '20px' },
	input: {
		backgroundColor: 'white',
		color: 'black',
		border: 'white',
		outline: 'white',
	},
	marginBottom30: { marginBottom: '30px' },
	linearProgress: { width: '80%', display: 'block', textAlign: 'center', margin: '20px auto 0px auto' },
	textCenter: { textAlign: 'center' },
}));

export function PasswordSet() {
	const classes = useStyles();

	const [_status, setStatus] = useState<boolean | null>(null);

	const [passwordWeak, setPasswordWeak] = useState(false);

	const [tryingSet, setTryingSet] = useState(false);

	const [password, setPassword] = useState('');

	const [passwordVerification, setpasswordVerification] = useState('');

	const toastContext = useContext(ToastContext);

	useEffect(() => {
		document.title = 'Patient Set password - Rocket Doctor';
	}, []); //html page title

	const passwordSetRequest = useCallback(async () => {
		setStatus(null);

		setTryingSet(true);

		const urlParams = new URLSearchParams(window.location.search);

		const email = urlParams.get('email');

		const token = urlParams.get('token');

		try {
			await post(`apiv2/pt/password-reset`, { email, password, token });
			setStatus(true);
			setTimeout(() => {
				passwordSetRoute.router?.navigate({ to: loginRoute.path });
			}, 5000);
			toastContext?.genericToastFn({
				open: true,
				text: 'Your password has been reset',
				severity: ToastSeverity.SUCCESS,
			});
		} catch (error) {
			setStatus(true);
			setTryingSet(false);
			toastContext?.genericToastFn({
				open: true,
				text: 'Error on update password',
				severity: ToastSeverity.ERROR,
			});
		}
	}, [password]);

	return (
		<div>
			<div className={classes.logo}>
				<RDLogoLight />
			</div>
			<Grid className={classes.gridMargin} container sx={{ minWidth: 390 }}>
				<Typography gutterBottom variant="h4" className={classes.textPlusMargin}>
					Your Email has Been Verified. Set up Your Password
				</Typography>
			</Grid>
			<Grid
				className={classes.gridMargin}
				container
				direction="row"
				justifyContent="space-evenly"
				alignItems="center"
				spacing={0}
			>
				<Grid container alignItems="center">
					<Grid item xs>
						<Typography variant="h6" className={classes.h6}>
							In order to protect your account, make sure your password is at least:
						</Typography>

						<Grid container item sx={{ justifyContent: 'left' }}>
							<List sx={{ listStyleType: 'disc', pl: 2, fontSize: '0.8rem' }}>
								<ListItem sx={{ display: 'list-item' }}>10 characters</ListItem>
								<ListItem sx={{ display: 'list-item' }}>1 lowercase</ListItem>
								<ListItem sx={{ display: 'list-item' }}>1 uppercase</ListItem>
								<ListItem sx={{ display: 'list-item' }}>1 number</ListItem>
							</List>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<FormControl className={classes.width300}>
							<TextField
								error={!!passwordWeak}
								required
								id="password"
								key="password"
								type="password"
								label="Password"
								value={password}
								margin="normal"
								variant="outlined"
								fullWidth={true}
								disabled={tryingSet}
								onChange={(e) => {
									setPasswordWeak(!validatePassword(e.target.value));
									setPassword(e.target.value);
								}}
							/>
						</FormControl>
						<FormControl className={classes.width300}>
							<TextField
								error={password !== passwordVerification}
								required
								id="password"
								key="password"
								type="password"
								label="Re-enter Password"
								value={passwordVerification}
								margin="normal"
								variant="outlined"
								fullWidth={true}
								disabled={tryingSet}
								onChange={(e) => {
									setpasswordVerification(e.target.value);
								}}
							/>
						</FormControl>
					</Grid>
					{tryingSet ? (
						<LinearProgress color="primary" className={classes.linearProgress} />
					) : (
						<Button
							disabled={!validatePassword(password) || password !== passwordVerification}
							variant="contained"
							color="primary"
							className={classes.submitBtn}
							onClick={() => passwordSetRequest()}
						>
							SET PASSWORD &nbsp;
							<LockIcon />
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
}
