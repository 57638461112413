import { Box, Drawer } from "@mui/material"
// import React, { useEffect } from "react"
import { DocumentView } from './DocumentView.tsx';

// let container;
interface IProps {
    appointmentId?: string;
    isParticipantPatient: boolean;
    uploadDrawer: boolean;
    setUploadDrawer: (value: boolean) => void;
}

const DocumentsDrawer = ({
    appointmentId,
    isParticipantPatient,
    uploadDrawer,
    setUploadDrawer
}: IProps) => {
    // useEffect(() => {
    //     container = window !== undefined ? () => window?.document.body : undefined;
    // });
    return isParticipantPatient ? <>
        <Box
            component="nav"
            aria-label="mailbox folders"
        >
            <Drawer
                anchor="bottom"
                // container={container}
                variant="temporary"
                open={uploadDrawer}
                onClose={() => setUploadDrawer(!uploadDrawer)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <div style={{ height: '60vh' }}>
                    <DocumentView appointmentId={appointmentId} />
                </div>
            </Drawer>
        </Box>
    </> : <></>
}

export default DocumentsDrawer;
