import { createRoute } from '@tanstack/react-router';

import { Documents } from '../../components/Documents/DocumentView.tsx';
import { authWithNavRoute } from '../../router/authRoute.tsx';

export const documentRoute = createRoute({
	path: '/documents',
	component: Documents,
	getParentRoute: () => authWithNavRoute,
});
