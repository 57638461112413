import { createRoute } from '@tanstack/react-router';
import { z } from 'zod';

import VideoPage from './index.tsx';
import VideoAppointment from './appointment.tsx';
import { authRootRoute } from '../../router/authRoute.tsx';

export const videoRoute = createRoute({
	path: 'video',
	component: VideoPage,
	getParentRoute: () => authRootRoute,
	validateSearch: z.object({
		id: z.string(),
	}).parse,
});

export const videoAppointmentRoute = createRoute({
	path: '$viewerId/$appointmentId',
	getParentRoute: () => videoRoute,
	component: VideoAppointment,
	parseParams: z.object({
		viewerId: z.string(),
		appointmentId: z.string(),
	}).parse,
});
