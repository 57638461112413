import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from 'react-query';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, LinearProgress, Stack, MenuItem, TextField, Autocomplete, createFilterOptions } from '@mui/material';

import { GENDERS, Patient, PRONOUNS, PROVINCE, SEX, STATES, ToastSeverity, ToastState } from '../../../lib/type.ts';
import { useMutatePatient } from '../../../lib/hooks/useGetPatient';

import { useGetPharmacyList } from './useGetPharmacyList';

export const filter = createFilterOptions({
	stringify: (option: any) => option.label,
});

type ProfileFormProps = {
	profileInfo?: Patient;
	isLoading?: Boolean;
	showToast?: ({ open, text, severity }: ToastState) => void;
};

const isUS = import.meta.env.VITE_SYSTEM_ENV === 'us';

export default function ProfileForm({ showToast }: ProfileFormProps) {
	const queryClient = useQueryClient();

	const {
		patient,
		handlers,
		isValid,
		validation,
		saveChanges,
		isSaving: isMutating,
		isFetching: isLoading,
	} = useMutatePatient({
		onSuccess: () => {
			showToast?.({
				open: true,
				text: 'Updated Successfully',
				severity: ToastSeverity.SUCCESS,
			});

			queryClient.invalidateQueries('patient');

			if (patient?.id) {
				queryClient.invalidateQueries(['patient', patient.id]);
			}
		},
		onError: (err: Error) => {
			showToast?.({
				open: true,
				severity: ToastSeverity.ERROR,
				text: err.message ?? 'Error on update profile',
			});
		},
	});

	const provinces = isUS ? STATES : PROVINCE;
	const provinceLabel = isUS ? 'State' : 'Province';

	const pharmacies = useGetPharmacyList(patient?.postalCode);

	const onPharmacyFieldChange = useCallback(
		({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			const selectedPharmacy = pharmacies?.find(({ id }) => id.toString() == value);
			handlers.onPharmacyFieldChange(selectedPharmacy);
		},
		[pharmacies],
	);

	return (
		<>
			<Box display="grid" gap={3} gridTemplateColumns="1fr 1fr">
				{isLoading ? (
					<Box gridColumn="span 2">
						<LinearProgress />
					</Box>
				) : null}

				{!isLoading ? (
					<>
						<TextField
							required
							fullWidth
							name="firstName"
							label="First Name"
							variant="outlined"
							value={patient?.firstName}
							error={validation?.firstName}
							onChange={handlers.onFieldChange}
							helperText={validation?.firstName?.message}
						/>

						<TextField
							required
							fullWidth
							name="lastName"
							label="Last Name"
							variant="outlined"
							value={patient?.lastName}
							error={validation?.lastName}
							onChange={handlers.onFieldChange}
							helperText={validation?.lastName?.message}
						/>

						<TextField
							required
							fullWidth
							name="email"
							type="email"
							variant="outlined"
							label="Email Address"
							value={patient?.email}
							error={validation?.email}
							onChange={handlers.onFieldChange}
							helperText={validation?.email?.message}
						/>

						<TextField
							type="tel"
							required
							fullWidth
							name="phoneNumber"
							label="Phone Number"
							variant="outlined"
							error={validation?.phoneNumber}
							onChange={handlers.onFieldChange}
							id="formatted-numberformat-input"
							helperText={validation?.phoneNumber?.message}
							value={patient?.phoneNumber?.replace?.(/^\+1/, '') ?? ''}
						/>

						<Autocomplete
							freeSolo
							clearOnBlur
							selectOnFocus
							handleHomeEndKeys
							value={patient?.gender}
							id="gender-autocomplete"
							onChange={(_event: any, selectedOption) => {
								if (typeof selectedOption === 'string') {
									handlers.onAutocompleteFieldChange(selectedOption, 'gender');
								} else if (selectedOption && selectedOption.value) {
									handlers.onAutocompleteFieldChange(selectedOption.value, 'gender');
								} else {
									handlers.onAutocompleteFieldChange(selectedOption, 'gender');
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);

								const { inputValue } = params;
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option.label);
								if (inputValue !== '' && !isExisting) {
									filtered.push({
										value: inputValue,
										label: `Other: "${inputValue}"`,
									});
								}

								return filtered;
							}}
							options={Object.entries(GENDERS).map(([, label]) => ({ value: label, label }))}
							getOptionLabel={(option: any) => {
								// Value selected with entering, right from the input
								if (typeof option === 'string') {
									return option;
								}
								// Regular option
								return option.label;
							}}
							renderOption={(props, option) => <li {...props}>{option.label}</li>}
							renderInput={(params) => <TextField {...params} label="Gender" />}
						/>

						<DatePicker
							disableFuture
							openTo="year"
							format="YYYY-MM-DD"
							label="Date of Birth"
							views={['year', 'month', 'day']}
							onChange={handlers.getOnValueUpdate('dob')}
							value={dayjs(patient?.dob ?? ' ')}
							slotProps={{
								textField: {
									required: true,
									error: validation?.dob,
									helperText: validation?.dob?.message,
								},
							}}
						/>

						<TextField
							select
							required
							fullWidth
							name="sex"
							variant="outlined"
							value={patient?.sex ?? ''}
							onChange={handlers.onFieldChange}
							label="Sex Assigned at Birth"
							error={validation?.sex}
							helperText={validation?.sex?.message}
						>
							{Object.entries(SEX).map(([value, label]) => (
								<MenuItem key={value} value={label}>
									{label}
								</MenuItem>
							))}
						</TextField>

						<Autocomplete
							value={patient?.pronouns}
							onChange={(_event: any, selectedOption) => {
								if (typeof selectedOption === 'string') {
									handlers.onAutocompleteFieldChange(selectedOption, 'pronouns');
								} else if (selectedOption && selectedOption.value) {
									handlers.onAutocompleteFieldChange(selectedOption.value, 'pronouns');
								} else {
									handlers.onAutocompleteFieldChange(selectedOption, 'pronouns');
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);

								const { inputValue } = params;
								// Suggest the creation of a new value
								const isExisting = options.some((option) => inputValue === option.label);
								if (inputValue !== '' && !isExisting) {
									filtered.push({
										value: inputValue,
										label: `Other: "${inputValue}"`,
									});
								}

								return filtered;
							}}
							selectOnFocus
							clearOnBlur
							handleHomeEndKeys
							id="pronouns-autocomplete"
							options={Object.entries(PRONOUNS).map(([, label]) => ({ value: label, label }))}
							getOptionLabel={(option) => {
								// Value selected with enter, right from the input
								if (typeof option === 'string') {
									return option;
								}
								// Regular option
								return option.label;
							}}
							renderOption={(props, option) => <li {...props}>{option.label}</li>}
							freeSolo
							renderInput={(params) => <TextField {...params} label="Pronouns" />}
						/>

						<TextField
							required
							fullWidth
							label="Address"
							name="address1"
							variant="outlined"
							value={patient?.address1}
							onChange={handlers.onFieldChange}
							error={validation?.address1}
							helperText={validation?.address1?.message}
						/>

						<TextField
							fullWidth
							name="address2"
							variant="outlined"
							value={patient?.address2}
							onChange={handlers.onFieldChange}
							label="Apartment, Suite etc."
							error={validation?.address2}
							helperText={validation?.address2?.message}
						/>

						<TextField
							required
							fullWidth
							name="city"
							label="City"
							variant="outlined"
							value={patient?.city}
							onChange={handlers.onFieldChange}
							error={validation?.city}
							helperText={validation?.city?.message}
						/>

						<TextField
							select
							required
							fullWidth
							name="state"
							label={provinceLabel}
							variant="outlined"
							value={patient?.state ?? ''}
							onChange={handlers.onFieldChange}
							error={validation?.state}
							helperText={validation?.state?.message}
						>
							{Object.entries(provinces).map(([value, label]) => (
								<MenuItem key={value} value={label}>
									{label}
								</MenuItem>
							))}
						</TextField>

						<TextField
							required
							name="postalCode"
							variant="outlined"
							label="Postal Code"
							value={patient?.postalCode}
							onChange={handlers.onFieldChange}
							error={validation?.postalCode}
							helperText={validation?.postalCode?.message ?? 'Example: A1B 2C3 (Canada) / 90210 (USA)'}
						/>

						{
							<TextField
								select
								fullWidth
								name="preferredPharmacy"
								label="Preferred Pharmacy"
								variant="outlined"
								value={patient?.preferredPharmacy?.id ?? ''}
								onChange={onPharmacyFieldChange}
								error={validation?.preferredPharmacy}
								helperText={validation?.preferredPharmacy?.message}
							>
								{pharmacies?.map((pharmacy) => (
									<MenuItem key={pharmacy.name} value={pharmacy.id}>
										{pharmacy.name} - {pharmacy.address}, {pharmacy.city}, {pharmacy.state}, {pharmacy.postal_code}
									</MenuItem>
								))}
							</TextField>
						}

						<span />

						<Stack direction="row" justifyContent="flex-end" gridColumn="span 2">
							<LoadingButton
								color="primary"
								variant="outlined"
								loading={isMutating}
								disabled={!isValid}
								onClick={saveChanges}
								loadingPosition="center"
							>
								Save
							</LoadingButton>
						</Stack>
					</>
				) : null}
			</Box>
		</>
	);
}
