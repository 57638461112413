import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { dehydrate } from 'react-query/hydration';
import { get, put } from '../api/_helper';
import { Patient, Pharmacy } from '../type.ts';
import { patientRequiredValidationSchema, patientValidationSchema } from '../validationSchemas/patientValidationSchema';

const url = `apiv2/pt/patients`;

export const useGetPatient = () => {
	const payload = useQuery<Patient>('patient', () => get(url));
	const [validationState, setValidationState] = useState<any>();

	useEffect(() => {
		patientRequiredValidationSchema
			.validate(payload?.data)
			.then((value: any) => setValidationState({ value, isValid: true }))
			.catch((error: any) => {
				setValidationState({ ...error, isValid: false });
				console.error('Validate Patient', error.message, error.data);
			});
	}, [payload?.data]);

	return {
		...payload,
		validationState,
		isValid: validationState?.isValid,
	};
};

export const useGetPrefetchPatient = async (Cookie = {}) => {
	const queryClient = new QueryClient();
	await queryClient.prefetchQuery<Patient>(['patient'], () =>
		get(url, {
			headers: { Cookie },
		}),
	);

	return dehydrate(queryClient);
};

const mutatePatientReducer = (
	prevState: Partial<Patient>,
	payload: Partial<Patient>,
): Partial<Patient> & { ts?: number } => {
	return {
		...prevState,
		...payload,
	};
};

const patientValidationReducer = (
	prevState: Partial<Record<keyof Patient, any>>,
	payload: Partial<Record<keyof Patient, any>>,
) => {
	return {
		...prevState,
		...payload,
	};
};

const savePatientInfo = (patientInfo: any) => put(url, { patientInput: patientInfo });

export const useMutatePatient = (options?: Record<any, any>) => {
	const { data, isFetching } = useGetPatient();

	// @ts-ignore
	const [patient, updatePatient] = useReducer(mutatePatientReducer, data);
	const [patientValidation, updatePatientValidation] = useReducer(patientValidationReducer, {});

	const { mutate, isLoading: isSaving } = useMutation(savePatientInfo, options);

	const saveChanges = useCallback(() => {
		patientRequiredValidationSchema
			.validate(patient)
			.then(() => {
				console.log('[useMutatePatient]', 'saveChanges', 'Validation:pass', { patient });
				mutate(patient);
			})
			.catch((err: Error) => {
				console.log('[useMutatePatient]', 'saveChanges', 'Validation:catch', { err });

				options?.onError?.(err);
			});
	}, [patient, mutate]);

	useEffect(() => {
		// @ts-ignore
		updatePatient(data);
	}, [data]);

	const handlers: any = {};

	handlers.onFieldChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: { name, value },
		} = evt;

		// @ts-ignore
		const fieldValidationSchema = patientValidationSchema.pick([name]);

		const payload = { [name]: value };

		console.log('[useMutatePatient]', 'onFieldChange', payload);

		fieldValidationSchema
			.validate(payload)
			.then(() => {
				console.log('[useMutatePatient]', 'onFieldChange', 'Validation:passd', { name });
				updatePatientValidation({ [name]: null });
			})
			.catch((err: Error) => {
				console.log('[useMutatePatient]', 'onFieldChange', 'Validation:catch', { [name]: err });

				updatePatientValidation({
					[name]: err,
				});
			});

		//@ts-ignore
		updatePatient(payload);
	}, []);

	handlers.getOnValueUpdate = useCallback(
		(filedName: string) => (value: unknown) => {
			handlers.onFieldChange({ target: { name: filedName, value } });
		},
		[handlers.onFieldChange],
	);

	handlers.onPharmacyFieldChange = useCallback((pharmacy: Pharmacy) => {
		const fieldValidationSchema = patientValidationSchema.pick(['preferredPharmacy']);
		const payload = { preferredPharmacy: pharmacy };

		fieldValidationSchema
			.validate(payload)
			.then(() => {
				console.log('[useMutatePatient]', 'onFieldChange', 'Validation:passd', 'preferredPharmacy');
				updatePatientValidation({ preferredPharmacy: null });
			})
			.catch((err: Error) => {
				console.log('[useMutatePatient]', 'onFieldChange', 'Validation:catch', 'preferredPharmacy');
				updatePatientValidation({ preferredPharmacy: err });
			});

		//@ts-ignore
		updatePatient(payload);
	}, []);

	handlers.onAutocompleteFieldChange = useCallback((value: string, name: string) => {
		// @ts-ignore
		const fieldValidationSchema = patientValidationSchema.pick([name]);

		const payload = { [name]: value };

		fieldValidationSchema
			.validate(payload)
			.then(() => {
				console.log('[useMutatePatient]', 'onFieldChange', 'Validation:passd', { name });
				updatePatientValidation({ [name]: null });
			})
			.catch((err: Error) => {
				console.log('[useMutatePatient]', 'onFieldChange', 'Validation:catch', { [name]: err });

				updatePatientValidation({
					[name]: err,
				});
			});

		// @ts-ignore
		updatePatient(payload);
	}, []);

	const isValid = useMemo(() => patientRequiredValidationSchema.isValidSync(patient), [patient]);

	return {
		handlers,

		patient,
		isValid,
		validation: patientValidation,

		saveChanges,

		isSaving,
		isFetching,
	};
};
