import { memo, useCallback } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import MuiAlert from '@mui/lab/Alert';
import { makeStyles } from '@mui/styles';

interface ColumnErrorPops {
  name: string;
  error: boolean;
  message?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    '& .MuiAlert-icon': {
      fontSize: '15px',
      padding: 3,
      marginRight: '1px',
    },
    '& .MuiAlert-message': {
      fontSize: '11px',
      padding: 3,
      marginRight: '4px',
    },
  },
  hideAlert: {
    visibility: 'hidden',
  },
}));

const SectionErrorLabel = memo((props: ColumnErrorPops) => {
  const classes = useStyles();

  const { error, message } = props;

  const handleAlertVisibility = useCallback(() => {
    return error ? '' : classes.hideAlert;
  }, [error]);

  return (
    <MuiAlert
      className={`${handleAlertVisibility()} ${classes.root}`}
      icon={<ErrorIcon fontSize="inherit" />}
      variant="filled"
      severity="error"
    >
      {message}
    </MuiAlert>
  );
});

export default SectionErrorLabel;
