import axios from 'axios';

export const login = async ({ email, password }: { email: string; password: string }) => {
	const apiURL = `${import.meta.env.VITE_APIV2}/apiv2/pt/login`;

	try {
		return await axios.post(apiURL, { email, password }, { withCredentials: true });
	} catch (error) {
		console.error(error);

		return error;
	}
};
