import { useState, useCallback, useMemo, ChangeEvent } from 'react';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Appointment } from '../../../lib/type.ts';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TabPanel } from '../../Common/TabPanel';
import AppointmentTable from './AppointmentTable';
import { makeStyles } from '@mui/styles';

interface Props {
  onClick: () => void;
  appointments: Appointment[];
  isSM: boolean;
}

enum AppointmentsViewTabs {
  upcomingAppointment,
  previousAppointment,
}

const a11yProps = (index: number) => {
  return {
    id: `dasboard-tab-${index}`,
    'aria-controls': `dasboard-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(() => ({
  disableElevation1: {
    '& .MuiPaper-elevation1': {
      // background: 'red',
      boxShadow: 'none',
    },
  },
}));

const AppointmentsView = ({ appointments, isSM, onClick }: Props) => {
  const css = useStyles();

  const [currentTab, setCurrentTab] = useState<AppointmentsViewTabs>(AppointmentsViewTabs.previousAppointment);
  const [apps] = useState(appointments);

  const handleChange = useCallback((_event: ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  }, []);

  const {
    hasUpcomingAppointment,
    hasPreviousAppointment,
    upcomingAppointmentList,
    previousAppointmentList,
  } = useMemo(() => {
    const upcoming = apps?.filter?.((appointment: Appointment) => dayjs(appointment?.dateTime).isAfter(dayjs()));

    const previous = apps?.filter?.((appointment: Appointment) => dayjs(appointment?.dateTime).isBefore(dayjs()));

    const hasUpcomingAppointment = !!upcoming?.length;
    const hasPreviousAppointment = !!previous?.length;

    if (hasUpcomingAppointment) {
      setCurrentTab(AppointmentsViewTabs.upcomingAppointment);
    } else {
      setCurrentTab(AppointmentsViewTabs.previousAppointment);
    }

    return {
      hasUpcomingAppointment,
      hasPreviousAppointment,
      upcomingAppointmentList: upcoming,
      previousAppointmentList: previous,
    };
  }, [apps, setCurrentTab]);

  return (
    <Box display="grid" mx={1} gap={2} className={css.disableElevation1}>
      <Grid container px={2}>
        <Grid item xs={12} md={7}>
          <Typography my={2} color={isSM ? 'black' : 'primary'} variant="h5">
            View, Join & Request an Appointment
          </Typography>
          <Typography variant={isSM ? 'body2' : 'body1'}>
            Request an appointment by clicking the "New Appointment" button. A member of our Patient Care team will be
            in touch with you soon.
          </Typography>
        </Grid>

        <Grid container item mt={2} xs={12} justifyContent="flex-start" direction="row-reverse" md={5} gap={2}>
          <Grid item xs={12} md={5}>
            <Button
              fullWidth
              color="primary"
              onClick={onClick}
              variant={isSM ? 'outlined' : 'contained'}
              startIcon={<AddCircleOutlineIcon />}
            >
              New Appointment
            </Button>
          </Grid>
          {/* NOTE: Disabled until function is ready */}
          {/*<Grid item xs={12} md={5}>*/}
          {/*  <Button onClick={onClick} color="primary" fullWidth variant="outlined" startIcon={<HelpOutlineIcon />}>*/}
          {/*    {isSM ? 'Previous Appointment help' : 'Appointment help'}*/}
          {/*  </Button>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>

      <Box sx={{ ml: 2, mr: { xs: 2, md: 5 }, mt: 4, backgroundColor: '#0000000A' }}>
        <Tabs
          variant="fullWidth"
          value={currentTab}
          onChange={handleChange}
          TabIndicatorProps={{ style: { backgroundColor: '#F50057' } }}
          sx={{ '.Mui-selected': { color: `black !important` } }}
        >
          {hasUpcomingAppointment ? (
            <Tab
              sx={{ alignItems: 'start' }}
              label={isSM ? 'Next' : 'Next Appointments'}
              value={AppointmentsViewTabs.upcomingAppointment}
              {...a11yProps(AppointmentsViewTabs.upcomingAppointment)}
            />
          ) : null}
          {hasPreviousAppointment ? (
            <Tab
              sx={{ alignItems: 'start' }}
              label={isSM ? 'Previous' : 'Previous Appointments'}
              value={AppointmentsViewTabs.previousAppointment}
              {...a11yProps(AppointmentsViewTabs.previousAppointment)}
            />
          ) : null}
        </Tabs>
      </Box>

      <TabPanel index={0} value={currentTab}>
        <AppointmentTable key="upcoming" onClick={onClick} appointments={upcomingAppointmentList} />
      </TabPanel>

      <TabPanel index={1} value={currentTab}>
        <AppointmentTable key="past" onClick={onClick} appointments={previousAppointmentList} isHistory />
      </TabPanel>
    </Box>
  );
};

export default AppointmentsView;
