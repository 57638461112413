import * as Sentry from '@sentry/react';
import axios from 'axios';
import { create } from 'zustand';
import { login } from '../api/login.ts';

type AuthState = {
	email?: string | undefined;
	hasAccessToken?: boolean;
	status: 'loggedOut' | 'loggedIn';
};

type AuthActions = {
	login: (email: string, password: string) => Promise<true | unknown>;
	loginToken: () => boolean;
	isLoggedIn: () => boolean;
	logout: () => Promise<void>;
};

export type UseAuthStore = AuthState & AuthActions;

export const useAuthStore = create<UseAuthStore>((set, get) => ({
	email: undefined,
	status: 'loggedOut',
	isLoggedIn() {
		return get().status === 'loggedIn';
	},
	loginToken() {
		const hasAccessToken = document.cookie.indexOf('loggedIn=') >= 0;

		console.log('useAuthStore->loginToken', { hasAccessToken });

		if (hasAccessToken) {
			set({ status: 'loggedIn', hasAccessToken });
		}

		return hasAccessToken;
	},
	async login(email: string, password: string) {
		const loginData = {
			email,
			password,
		};

		console.log('useAuthStore->login', email, password.length);

		const result = (await login(loginData)) as any;

		if (result?.status === 200) {
			console.log('useAuthStore->login', 'successful');

			set({ email, status: 'loggedIn' });

			return true;
		}

		console.log('useAuthStore->login', 'failed');

		throw new Error(result?.response?.data?.error);
	},
	async logout() {
		Sentry.addBreadcrumb({
			type: 'store:useAuthStore',
			level: 'info',
			message: 'logout: logout user and redirect to login page'
		});

		document.cookie = '';
		set({ email: undefined, status: 'loggedOut' });

		await axios.post(`${import.meta.env.VITE_APIV2}/apiv2/pt/logout`)
			.then(() => {
				console.log('useAuthStore', 'logout', 'successful');
				Sentry.addBreadcrumb({
					type: 'store:useAuthStore',
					category: 'api',
					level: 'info',
					message: 'logout: api/logout successful'
				});
			})
			.catch((error) => {
				Sentry.captureException({
					type: 'store:useAuthStore',
					category: 'api',
					message: 'logout: api/logout failed',
					error,
				})
			})

		Sentry.captureMessage("logging out")

		window.redirect('/login');
	},
}));

// @ts-ignore
window.useAuthStore = useAuthStore;
