import { createRoute } from '@tanstack/react-router';

import HomePage from '../../components/home';
import {authWithNavRoute} from '../../router/authRoute.tsx';

export const homeRoute = createRoute({
	path: '/',
	component: HomePage,
	getParentRoute: () => authWithNavRoute,
});
