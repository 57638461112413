import { useContext, useEffect } from 'react';
import { Box, Stack, Divider, LinearProgress, Typography } from '@mui/material';

import HealthCard from '../../components/Patient/Profile/Healthcard';
import ProfileForm from '../../components/Patient/Profile/Profile';
import { ToastContext } from '../../lib/context/ToastContext';
import { useGetPatient } from '../../lib/hooks/useGetPatient';
import { useBreakpoints } from '../../lib/hooks/useBreakpoints';

export function Profile() {
	const toastContext = useContext(ToastContext);
	const { data, isLoading } = useGetPatient();
	const { isSM } = useBreakpoints();

	useEffect(() => {
		document.title = 'Patient Profile - Rocket Doctor';
	}, []); //html page title

	return (
		<Box display="grid" gridTemplateColumns={isSM ? '1fr' : '5px 50vw 1fr'}>
			<span />
			<Box display="grid" gridTemplateColumns="1fr" gap={3} margin={2}>
				<Stack direction="column">
					<Typography gutterBottom paragraph variant="h3" color="initial">
						Profile
					</Typography>
					<Divider />
				</Stack>

				{isLoading ? <LinearProgress /> : null}

				{!isLoading ? (
					<ProfileForm profileInfo={data} isLoading={isLoading} showToast={toastContext.genericToastFn} />
				) : null}

				{!isLoading && data?.insurances ? (
					<HealthCard patientId={data.id} showToast={toastContext.genericToastFn} />
				) : null}
			</Box>
			<span />
		</Box>
	);
}
