import { useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Box, Stack } from '@mui/material';
import { Divider, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from 'react-query';
import {
	insuranceTypeCAN,
	insuranceTypeCodeCA,
	insuranceTypeUSA,
	ToastSeverity,
	ToastState,
} from '../../../lib/type.ts';
import { useBreakpoints } from '../../../lib/hooks/useBreakpoints';
import { useMutateInsurance } from '../../../lib/hooks/useGetInsurance';
import { isUS, mapInsuranceType, NONE_VALUE } from '../../../lib/_helpers.ts';

const objToValueLabel = (obj: Record<string, string>) =>
	Object.entries(obj).map(([value, label]) => ({ value, label }));

type HealthcareProps = {
	patientId: string;
	showToast?: ({ open, text, severity }: ToastState) => void;
};

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Healthcard({ patientId, showToast }: HealthcareProps) {
	const queryClient = useQueryClient();
	const { isSM, isMD, isLG } = useBreakpoints();
	const {
		insurance,
		saveChanges,
		isSaving: isMutating,
		onFieldChange: handleChange,
	} = useMutateInsurance({
		onSuccess: () => {
			showToast?.({ open: true, text: 'Updated Successfully', severity: ToastSeverity.SUCCESS });
			queryClient.invalidateQueries(['patient', patientId]);
		},
		onError: () => showToast?.({ open: true, text: 'Error on update healthcard', severity: ToastSeverity.ERROR }),
	});
	const mappedType = mapInsuranceType(insurance?.insuranceType);

	const fieldsDisplay: Partial<{
		showIssued: boolean;
		showVersion: boolean;
		showExpiration: boolean;
		labelIssued: string;
		labelVersion: string;
		labelExpiration: string;
	}> = useMemo(() => {
		switch (mappedType) {
			case insuranceTypeCodeCA.MSP:
			case insuranceTypeCodeCA.NBM:
				return {
					showIssued: true,
					showExpiration: true,
				};
			case insuranceTypeCodeCA.OHIP:
				return {
					showVersion: true,
					showExpiration: true,
				};
			case insuranceTypeCodeCA.ACHIP:
				return {
					showExpiration: true,
					labelExpiration: 'Expiration Date (if included)',
				};
			default:
				return {};
		}
	}, [insurance?.insuranceType]);

	const insuranceTypesRequiringDefaults = [insuranceTypeCodeCA.MSP, insuranceTypeCodeCA.NBM];

	function isInsuranceTypeCodeCA(type: string | undefined): type is insuranceTypeCodeCA {
		return Object.values(insuranceTypeCodeCA).includes(type as insuranceTypeCodeCA);
	}

	const requiresDefaultDates =
		isInsuranceTypeCodeCA(insurance?.insuranceType) &&
		insuranceTypesRequiringDefaults.includes(insurance?.insuranceType);

	const shouldDefaultIssued = fieldsDisplay?.showIssued && requiresDefaultDates && !insurance?.issuedOn;

	const shouldDefaultExpiration = fieldsDisplay?.showExpiration && requiresDefaultDates && !insurance?.expiresOn;

	const issuedDateValue = useMemo(() => {
		if (shouldDefaultIssued) {
			return dayjs().startOf('day');
		}
		if (insurance?.issuedOn) {
			const date = dayjs.utc(insurance.issuedOn);
			return date;
		}
		return null;
	}, [shouldDefaultIssued, insurance?.issuedOn]);

	const expirationDateValue = useMemo(() => {
		if (shouldDefaultExpiration) {
			return dayjs().startOf('day');
		}
		if (insurance?.expiresOn) {
			const date = dayjs.utc(insurance.expiresOn);
			return date;
		}
		return null;
	}, [shouldDefaultExpiration, insurance?.expiresOn]);

	const isInsuranceSelected =
		insurance?.insuranceType && insurance.insuranceType !== NONE_VALUE

	const gridTemplateCol = useMemo(() => {
		if (isSM) {
			return '1fr';
		}

		if (isMD) {
			return '1fr 1fr';
		}

		return '2fr 1fr 1fr';
	}, [isSM, isMD]);

	const boxSpanMD = useMemo(() => {
		if (isMD) {
			return 'span 2';
		}

		return undefined;
	}, [isMD]);

	const boxSpan4 = useMemo(() => {
		if (isSM) {
			return undefined;
		}

		if (isMD) {
			return 'span 2';
		}

		return 'span 3';
	}, [isSM, isMD]);

	return (
		<>
			<Box display="grid" gap={3} gridTemplateColumns={gridTemplateCol}>
				<Stack direction="column" gridColumn={boxSpan4}>
					<Typography variant="h5" color="initial">
						Insurance / Health Card (Optional)
					</Typography>
					<Divider />
				</Stack>

				<Box gridColumn={boxSpanMD}>
					<TextField
						select
						fullWidth
						variant="outlined"
						name="insuranceType"
						label="Insurance Type"
						value={insurance?.insuranceType || NONE_VALUE}
						onChange={handleChange}
					>
						<MenuItem value={NONE_VALUE}>
							<em>None</em>
						</MenuItem>
						{(isUS ? insuranceTypeUSA : objToValueLabel(insuranceTypeCAN)).map(({ value, label }) => (
							<MenuItem key={value} value={value}>
								{label}
							</MenuItem>
						))}
					</TextField>
				</Box>

				{isLG && <Box gridColumn="span 3" />}

				{isInsuranceSelected && (
					<TextField
						fullWidth
						variant="outlined"
						name="insuranceNumber"
						label="Insurance Number"
						value={insurance?.insuranceNumber}
						onChange={handleChange}
					/>
				)}

				{!isUS && fieldsDisplay?.showVersion ? (
					<TextField
						fullWidth
						name="versionCode"
						variant="outlined"
						onChange={handleChange}
						value={insurance?.versionCode}
						label={fieldsDisplay?.labelVersion ?? 'Version'}
					/>
				) : null}

				{fieldsDisplay?.showIssued ? (
					<DatePicker
						disableFuture
						openTo="year"
						format="YYYY-MM-DD"
						views={['year', 'month', 'day']}
						label={fieldsDisplay?.labelIssued ?? 'Issue Date'}
						value={issuedDateValue}
						timezone="UTC"
						onChange={(value) => {
							handleChange({
								target: {
									name: 'issuedOn',
									value: value ? value.utc().startOf('day').format('YYYY-MM-DD') : null,
								},
							});
						}}
					/>
				) : null}

				{fieldsDisplay?.showExpiration ? (
					<DatePicker
						disablePast
						openTo="year"
						format="YYYY-MM-DD"
						views={['year', 'month', 'day']}
						label={fieldsDisplay?.labelExpiration ?? 'Expiration Date'}
						value={expirationDateValue}
						timezone="UTC"
						onChange={(value) => {
							handleChange({
								target: {
									name: 'expiresOn',
									value: value ? value.utc().startOf('day').format('YYYY-MM-DD') : null,
								},
							});
						}}
					/>
				) : null}
				<Stack direction="row" gridColumn={boxSpan4} justifyContent="flex-end">
					<LoadingButton
						color="primary"
						variant="outlined"
						loading={isMutating}
						onClick={saveChanges}
						loadingPosition="center"
					>
						Save
					</LoadingButton>
				</Stack>
			</Box>
		</>
	);
}