import CircularProgress from '@mui/material/CircularProgress';
import { getRouteApi } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

import { get } from '../../lib/api/_helper';

const fileRoute = getRouteApi('/_auth/_nav/file/$fileId');

export function ViewFile() {
	const { fileId } = fileRoute.useParams();

	const [boxUrl, setBoxUrl] = useState('');
	const [loadingBoxUrl, setLoadingBoxUrl] = useState(false);

	useEffect(() => {
		async function getViewFileUrl() {
			try {
				setLoadingBoxUrl(true);
				const { signedUrl } = await get(`/apiv2/pt/medicalForms/${fileId}/embeddedLink`);

				setBoxUrl(signedUrl);

				setLoadingBoxUrl(false);
			} catch (error) {
				console.error(error);
			}
		}

		if (fileId) {
			getViewFileUrl();
		}
	}, [fileId]);

	return (
		<>
			{boxUrl && !loadingBoxUrl ? (
				<iframe
					src={boxUrl}
					title="BoxFileView"
					style={{ width: '100%', height: '100vh', border: 0, outline: 0 }}
				></iframe>
			) : (
				<CircularProgress />
			)}
		</>
	);
}
