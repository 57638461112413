import React, { useCallback, useEffect, useReducer } from 'react';
import { useMutation } from 'react-query';
import { put } from '../api/_helper';
import { Insurance, insuranceTypeCodeCA } from '../type';
import * as yup from 'yup';
import { useGetPatient } from './useGetPatient';
import { healthCardValidationSchema } from '../validationSchemas/healthCardValidationSchema';
import { NONE_VALUE } from '../_helpers';

const getUrl = (patientId: any) => `apiv2/pt/patients/${patientId}/insurance`;

export const useGetInsurance = () => {
  const { data: patient, ...payload } = useGetPatient();
 	// Ensure that the most-recently-updated insurance comes first, with updatedAt descending.
	// This is not a problem for Canada, where patients can only have on active insurance.
	// However, Product will spend some time considering what UX and behavior they want, for the USA,
	// where patients can have more than one active health insurance. For now, we treat the US patients
	// like Canadian patients -- only the most-recently-updated insurance is displayed and edited.
const patientInsurances = patient?.insurances?.sort((a, b) =>
	(b.updatedAt?.toString() ?? '').localeCompare(a.updatedAt?.toString() ?? ''),
  );

  return {
    ...payload,
    patientId: patient?.id,
    insurances: patientInsurances,
  };
};

const mutateDataReducer = (prevState: Partial<Insurance>, payload: Partial<Insurance>) => {
  return {
    ...prevState,
    ...payload,
  };
};

const patientValidationReducer = (
  prevState: Partial<Record<keyof Insurance, any>>,
  payload: Partial<Record<keyof Insurance, any>>,
) => {
  return {
    ...prevState,
    ...payload,
  };
};

const saveInsurance = ({ patientId, insurance }: { patientId: any; insurance: Partial<Insurance> }) =>
  put(getUrl(patientId), insurance);

export const useMutateInsurance = (options?: Record<any, any>) => {
  const {
    insurances,
    patientId,
    isFetching,
  } = useGetInsurance();

  // Safely extract the first insurance data or use an empty object
  const [insuranceData] = insurances || [{}];

  const [insurance, updateInsurance] = useReducer(mutateDataReducer, insuranceData || {});
  const [insuranceValidation, updatePatientValidation] = useReducer(patientValidationReducer, {});

  const insuranceTypesRequiringDefaults = [insuranceTypeCodeCA.MSP, insuranceTypeCodeCA.NBM];

  const { mutate, isLoading: isSaving } = useMutation(saveInsurance, options);

  const saveChanges = useCallback(() => {
    mutate({
      patientId,
      insurance,
    });
  }, [insurance, mutate, patientId]);

  useEffect(() => {
    if (insuranceData) {
      updateInsurance(insuranceData);
    }
  }, [insuranceData]);

  const onFieldChange = useCallback(
	(evt: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: any } }) => {
	  const {
		target: { name, value },
	  } = evt;
  
	  let updatedInsurance = { ...insurance, [name]: value };
  
	  if (name === 'insuranceType') {
		if (value === NONE_VALUE) {
		  updatedInsurance = {
			insuranceType: '',
			insuranceNumber: '',
			issuedOn: undefined,
			expiresOn: undefined,
			versionCode: '',
		  };
		} else if (insuranceTypesRequiringDefaults.includes(value as insuranceTypeCodeCA)) {
		  if (!updatedInsurance.issuedOn) {
			updatedInsurance.issuedOn = new Date();
		  }
		  if (!updatedInsurance.expiresOn) {
			updatedInsurance.expiresOn = new Date();
		  }
		} else {
		  updatedInsurance.issuedOn = undefined;
		  updatedInsurance.expiresOn = undefined;
		}
	  }
  
	  updateInsurance(updatedInsurance);
  
	  healthCardValidationSchema
		.validate(updatedInsurance, { abortEarly: false })
		.then(() => {
		  updatePatientValidation({});
		})
		.catch((validationErrors: yup.ValidationError) => {
			const errors: Record<string, string> = {};
			validationErrors.inner.forEach((error) => {
			  if (error.path) {
				errors[error.path] = error.message;
			  }
			});
			updatePatientValidation(errors);
		  });
	},
	[updateInsurance, updatePatientValidation, insurance, insuranceTypesRequiringDefaults],
  );

  return {
    onFieldChange,
    insurance,
    validation: insuranceValidation,
    saveChanges,
    isSaving,
    isFetching,
  };
};