import { z } from 'zod';
import { createRoute } from '@tanstack/react-router';

import {authWithNavRoute } from '../../router/authRoute.tsx';

import { ViewFile } from './file.tsx';

export const fileRoute = createRoute({
	component: ViewFile,
	path: '/file/$fileId',
	parseParams: z.object({
		fileId: z.coerce.number(),
	}).parse,
	getParentRoute: () => authWithNavRoute,
});
