import * as Sentry from '@sentry/react';
import * as SentryIntegrations from "@sentry/integrations";

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.VITE_SYSTEM_ENV,
	sendDefaultPii: true, // This option is required for capturing headers and cookies.
	integrations: [
		SentryIntegrations.httpClientIntegration(),
		SentryIntegrations.captureConsoleIntegration(),
		Sentry.browserApiErrorsIntegration(),
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: true,
			blockAllMedia: true,
			unmask: ['.cfg-replay-unmask'],
			networkDetailAllowUrls: [
				import.meta.env.VITE_API,
				import.meta.env.VITE_APIV2,
				'https://apiv2.rocketdoctor.dev/',
				'https://apiv2.rocketdoctor.ca/',
				'https://apiv2.rocketdoctor.us/',
			],
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/apiv2.rocketdoctor\.dev/, /^https:\/\/apiv2.rocketdoctor\.us/, /^https:\/\/apiv2.rocketdoctor\.ca/],
	// Session Replay
	replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
